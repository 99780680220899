.doctor-dashboard {
  display: flex;
  background-image: url(https://www.medbridgeaccess.com/static/banner/dash-back.png);
  background-size: cover;
  background-position: center;
  object-fit: cover;
  font-family: "Nunito", sans-serif !important;
  width: 100%;
  overflow-y: auto;
  flex-direction: column;
}
.doctor-dashboard-overlay {
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.doctor-dashboard h1,
.doctor-dashboard h2,
.doctor-dashboard h3,
.doctor-dashboard h4,
.calendar-title {
  font-weight: 800;
  font-family: "Nunito", sans-serif !important;
  color: rgb(76, 89, 117);
}
.dashboard-h2 {
  margin: 0;
  text-align: start;
  padding-left: 30px;
  font-size: 2rem;
  font-weight: 700 !important;
}
.dashboard-logo {
  width: 100%;
  background-color: white;
}
.dashboard-content {
  padding: 40px;
  height: 700px;
}
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-options {
  display: flex;
}

.dashboard-options button {
  margin-right: 10px;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  outline: none;
}

.dashboard-options button.active {
  background-color: white;
  border-radius: 15px 15px 0 0;
}

.user-icon {
  position: relative;
  font-size: 24px;
  cursor: pointer;
}

.user-menu {
  position: absolute;
  right: 0;
  top: 30px;
  background: #fff;
  border: 1px solid #ddd;
  width: 100px;
  text-align: left;
}

.user-menu div {
  padding: 10px;
  cursor: pointer;
}

.user-menu div:hover {
  background: #f0f0f0;
}
.cases-subcontainer {
  padding: 20px 40px;
  background-color: white;
}
.cases-container {
  padding: 30px 10px;
}

.cases-header {
  display: flex;
  font-weight: bold;
  margin-bottom: 10px;
}

.case-header-cell {
  flex: 1;
  padding: 10px;
  color: rgb(109, 109, 109);
  border-bottom: 1.5px solid #ddd;
}

.cases-list {
  margin-top: 10px;
}

.case-row {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  color: rgb(168, 168, 168);
}

.case-cell {
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.patientname {
  font-size: 18px;
  color: rgb(87, 86, 86);
  font-weight: 600;
}

.date .day {
  font-size: 40px;
  font-weight: bold;
}

.date .month-year {
  font-size: 12px;
}

.reports .report-link {
  display: block;
  margin: 5px 0;
  color: #007bff;
  text-decoration: none;
}

.reports .report-link:hover {
  text-decoration: underline;
}
/* Modal Styles */
.dashboard-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  margin: 0;
  font-size: 18px;
}

.close-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

hr {
  margin: 10px 0;
}

.modal-body {
  margin-top: 10px;
}

.modal-text {
  text-align: left;
  font-size: 15px;
}

.modal-reports .report-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.report-icon {
  width: 40px;
  height: 40px;
}

.report-link {
  flex: 1;
}

.report-date {
  margin-left: 10px;
  font-size: 0.8rem;
  color: #888;
}

/* Button Styles */
.case-cell button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}

.case-cell button:hover {
  background-color: #0056b3;
}

/* Date Cell Styles with Different Colors */

.case-cell.date:nth-child(odd) .day {
  color: #ff6347; /* Tomato color */
}

.case-cell.date:nth-child(even) .day {
  color: #4682b4; /* SteelBlue color */
}

.case-cell.date:nth-child(odd) .month-year {
  color: #ff6347; /* Tomato color */
}

.case-cell.date:nth-child(even) .month-year {
  color: #4682b4; /* SteelBlue color */
}
@media (max-width: 768px) {
  .doctor-dashboard {
    height: auto;
    flex-direction: column;
  }

  .dashboard-box {
    flex-direction: column;
  }
  .dashboard-doctor {
    width: 95%;
  }
}
