.footer-header {
  color: white;
  padding: 30px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.footer-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(142, 59, 110, 0.46); /* Overlay color */
  z-index: 1;
}

.footer-header-content {
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.footer-header h1 {
  width: 40%;
  margin: 0;
  font-weight: bold;
}

.contact-button {
  background-color: black;
  color: white;
  border: none;
  padding: 20px 25px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
}

.contact-button:hover {
  background-color: #333;
}

.footer-box{
    width: 100%;
    background-color: #840442;
   display: flex;
   align-items: center;
   justify-content: center;
}
.footer {
  
  color: #fff;
  padding: 50px 0px 30px;
  width: 75%;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.footer-section {

  padding: 10px;

  text-align: left;
}
.footer-content .about{
   width: 40%;
}
.about p{
    width: 80%;
}
.footer-content .links{
 width: 15%;
 text-align: start;
}
.footer-content .contact{
    width: 30%;
}

.logo-text {
  font-size: 24px;
  font-weight: bold;
}

.logo-text span {
  color: #fff;
}

.footer-section h2 {
  font-size: 18px;
  margin-bottom: 15px;
}

.footer-section ul {
 
  padding: 0;
  margin-top: 5px;
}

.footer-section ul li {
  margin-bottom: 3px;
}

.footer-section ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 13px;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}
.footer-section p{
    font-size: 13px;
}

.contact-info p {
  margin: 5px 0;
}

.socials a {
  color: #fff;
  margin: 0 10px;
  font-size: 18px;
  text-decoration: none;
}

.footer-bottom {
  border-top: 1px solid #c3098e;
  padding-top: 10px;
  margin-top: 20px;
  font-size: 14px;

}
.contact-info a{
  text-decoration: none;
  color: white;
}
@media (max-width:768px) {
  footer{
    font-size: 12px;
  }
  .footer{
    width: 90%;
  }
  
  .footer-header-content {
flex-direction: column;
    
  }
  .footer-header h1 {
    width: 90%;
    margin-bottom: 20px;
   
}

.footer-content{
  flex-direction: column;
  
}
.footer-section{
  width: 100% !important;
}
.contact-button{
  padding: 15px 25px;
}
}