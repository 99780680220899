/* Components/VitalsModal.css */

/* Modal Overlay */
.vitals-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Content */
  .vitals-modal-content {
    background-color: #fff;
    padding: 2rem;
    width: 90%;
    max-width: 500px;
    border-radius: 8px;
    position: relative;
  }
  
  .vitals-modal-title {
    margin-top: 0;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  /* Form Fields */
  .vitals-modal-field {
    margin-bottom: 1rem;
  }
  
  .vitals-modal-field label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .vitals-modal-field input {
    width: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
  }
  
  /* Error Message */
  .vitals-modal-error {
    color: red;
    margin-bottom: 1rem;
  }
  
  /* Modal Actions */
  .vitals-modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
  }
  
  .vitals-modal-actions button {
    margin-left: 1rem;
    padding: 0.5rem 1rem;
  }
  
  .vitals-modal-actions button:first-child {
    background-color: #007bff;
    color: #fff;
    border: none;
  }
  
  .vitals-modal-actions button:last-child {
    background-color: #6c757d;
    color: #fff;
    border: none;
  }
  .blood-pressure-field {
    display: flex;
    flex-direction: column;
  }
  
  .blood-pressure-inputs {
    display: flex;
    align-items: center;
  }
  
  .blood-pressure-inputs input {
    width: 60px;
    margin-right: 5px;
  }
  
  .blood-pressure-inputs span {
    margin-right: 5px;
  }
  
  