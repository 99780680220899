/* ----------------------------
   Timeline Container
----------------------------- */
.timeline-container {
  width: 100%;
}

.timeline-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.timeline-header h2 {
  font-size: 1.7rem;
  color: white;
  font-weight: 600;

}

.view-all-btn {
  background-color: #7e90c2;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.view-all-btn:hover {
  background-color: #5a6fa4;
}

/* ----------------------------
   Timeline Box
----------------------------- */
.timeline-box {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* ----------------------------
   Timeline Card
----------------------------- */
.timeline-card {
  display: flex;
  flex-direction: column;
  border-left: 4px solid #7e90c2; /* Default color */
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease;
  align-items: center;
}


.timeline-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* ----------------------------
   Timeline Card Main Sections
----------------------------- */
.timeline-card-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px ;
  width: 95%;
}

/* Left Section */
.timeline-card-left {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  gap: 4px;
  cursor: pointer;
  padding: 25px 0;
  width: 32%;
}

.problem-description-doctor {
  font-size: 1.2rem;
  font-weight: 500;
  color: #1a1a1a;
}

.status {
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 4px;
  width: fit-content;
  position: relative; /* For the status dot */
  padding-left: 16px; /* Space for the status dot */
}

/* ----------------------------
   Status Dot
----------------------------- */
.status::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: currentColor; /* Inherits color from the status class */
}

/* Status Color Variants */
.status.purple {
  color: #7c3aed;
}

.status.orange {
  color: #f97316;
}

.status.blue {
  color: #2563eb;
}

.status.green {
  color: #16a34a;
}

/* Right Section */
.timeline-card-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  position: relative;
}

.date-time {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  padding: 25px 0;
  width: 23%;
}

.date,
.time {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  color: #4b5563;
}

.date-time .icon {
  margin-right: 4px;
  color: #007bff;
}
.date{
  color: black;
  font-weight: 500;
}
.patient-info-big-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
}

.patient-info {
  display: flex;
  align-items: center;
  padding: 25px 0;
  width: 80%;
}

.patient-name {
  font-size: 1.2rem;
  font-weight: 500;
  color: #1a1a1a;
}

/* ----------------------------
   Avatar Circle
----------------------------- */
.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #7e90c2; /* Default color, overridden by color classes */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.3rem;
  margin-right: 12px;
}

.border-purple .avatar {
  background-color: #7c3aed;
}

.border-orange .avatar {
  background-color: #f97316;
}

.border-blue .avatar {
  background-color: #2563eb;
}

.border-green .avatar {
  background-color: #16a34a;
}

/* Right Arrow */
.right-arrow {
  cursor: pointer;
  color: #6b7280;
  transition: color 0.3s ease;
}

.right-arrow:hover {
  color: #2563eb;
}

/* ----------------------------
   Timeline Card Details (Expanded)
----------------------------- */
.timeline-card-details {
  padding: 0 16px 16px;
  border-top: 1px solid #f0f0f0;
}

.timeline-card-row {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.timeline-card-row:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}

.label {
  color: #6b7280;
  font-size: 14px;
}

.value {
  color: #1a1a1a;
  font-weight: 500;
}

/* Actions within Expanded Details */
.timeline-card-actions {
  display: flex;
  gap: 12px;
  margin-top: 12px;
}
.patient-info-box{
  text-align: left;
}
.patient-info-box .gender{
  font-size: 1rem;
}
.timeline-card i{
  color: #007bff;
}
.action-button {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.edit-button {
  background-color: #f3f4f6;
  color: #1a1a1a;
}

.edit-button:hover {
  background-color: #dbeafe;
  color: #2563eb;
}

.delete-button {
  background-color: #f3f4f6;
  color: #1a1a1a;
}

.delete-button:hover {
  background-color: #fee2e2;
  color: #dc2626;
}

/* ----------------------------
   Border Color Classes
----------------------------- */
.border-purple {
  border-left-color: #7c3aed;
}

.border-orange {
  border-left-color: #f97316;
}

.border-blue {
  border-left-color: #2563eb;
}

.border-green {
  border-left-color: #16a34a;
}

/* ----------------------------
   Loading, Error, and Empty States
----------------------------- */
.timeline-loading,
.timeline-error,
.timeline-empty {
  padding: 24px;
  text-align: center;
  color: #6b7280;
  background: #f9fafb;
  border-radius: 8px;
}

.timeline-error {
  color: #dc2626;
  background: #fef2f2;
}

/* ----------------------------
   Responsive Design
----------------------------- */
@media (max-width: 1024px) {
  .timeline-container {
    padding: 16px 0;
  }
}

@media (max-width: 640px) {
  .timeline-header h2 {
    font-size: 20px;
  }

  .action-button {
    font-size: 12px;
    padding: 6px 10px;
  }

  .timeline-card-main {
    flex-direction: column;
    align-items: flex-start;
    width: 92%;
  }
  

  .timeline-card-right {
    align-items: flex-start;
  }

  .timeline-card-left,
  .timeline-card-right {
    width: 100%;
  }

  .right-arrow {
    position: static;
    align-self: flex-end;
    margin-top: 8px;
  }

  .timeline-card-header {
    padding: 12px;
  }

  .timeline-card-details {
    padding: 0 12px 12px;
  }
  .date, .time {
    
    font-size: 0.9rem;
    
}
  .date-time {

    flex-direction: row;
    gap: 4px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    width: 100%;
  }
  .patient-info-big-box {
    width: 100%;
}
  .avatar {
    width: 50px;
    height: 50px;
    font-size: 1rem;
  }

  .timeline-card {
    padding-bottom: 16px;
  }
}

/* ----------------------------
   Additional Styling Adjustments
----------------------------- */
/* Ensure the right-arrow doesn't overlap content */
/* Already handled above with .timeline-card-right padding */
/* Adjust for smaller screens to prevent overflow */
/* Already handled in responsive @media queries */
/* Filter Section Styles */

.timeline-filter {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  gap: 10px;
  background-color: rgba(255, 255, 255, 0.476);
  border-radius: 20px;
}

.filter-button {
  padding: 15px ;
  border: none;
  background-color: transparent;
  color: #333;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  width: 25%;
  border-radius: 20px;

}

.filter-button:hover {
  background-color: #e0e0e0;
}

.filter-button.active {
  background-color: #333;
  color: #fff;
}

/* Optional: Responsive adjustments */
@media (max-width: 600px) {
  .filter-button {
    padding: 6px 12px;
    font-size: 14px;
  }
}