.form-container {
    display: flex;
    justify-content: space-around;
    padding: 60px;
    margin:0 0;
    background-color: rgb(248, 246, 246);
  }

 

.steps {
    width: 45%;
    text-align: left;
  }
  
  .steps h2 {
    color: #ac054e;
  }
  
  .steps h3 {
    font-size: 24px;
    margin-bottom: 30px;
  }
  
  .step {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  
  .step-number {
    background-color: #ac054e;
    color: white;
    padding: 10px 15px;
   border-radius: 5px;
    margin-right: 20px;
  }
  
  .step-description h4 {
    margin: 0;
  }
  
  .step-description p {
    margin: 5px 0 0;
  }
  
  /* Define keyframes for sliding in from the top */
@keyframes slideInFromTop {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Initial state before animation */
.form {
  width: 35%;
  background-color: #ac054e;
  padding: 30px 20px;
  border-radius: 10px;
  text-align: left;
  
  opacity: 0;
  transform: translateY(-100px);
  
  /* Transition for smooth animation */
  transition: opacity 2s ease-out, transform 2s ease-out;
}

/* Visible state when in viewport */
.form.visible {
  opacity: 1;
  transform: translateY(0);
}

  
  .form h3, .form h1 {
    color: white;
    margin: 0 0 10px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  form input, form textarea, #country,#gender {
    margin: 10px 0 ;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 5px;
  }
  .react-select-signup{
margin: 10px 0;
padding: 0 !important;
  }
 
 
  
  form button {
    padding: 20px;
    background-color:#740133;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
 
    margin: 10px 0;
  }
  .back-button{
    background-color: transparent !important;
    color: black !important;
    text-decoration: underline !important;
  }
  .form-end-login{
    width: 100%;
    text-align: center;
    font-size: 14px;
  }
  .form-end-login .login-link{
    color: white;
    font-size: 16px;
    cursor: pointer;

  }
  .upload-box {
    border-radius: 10px;
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    transition: border-color 0.3s ease;
    margin-bottom: 10px;
    background-color: rgb(255, 255, 255);
  }
  
  .upload-box.dragging {
    border-color: #3f51b5;
  }
  
  .upload-content {
    text-align: center;
  }
  
  .upload-icon {
    width: 60px;
  }
  
  .upload-instructions {
    font-size: 12px;
    color: #888;
    margin-top: 0px;
  }

.file-preview {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.file-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9;
  position: relative;
}

.file-thumbnail img {
  width: 50px;
  height: 50px;
}

.file-details {
  margin-left: 10px;
  flex-grow: 1;
}

.file-details p {
  margin: 0;
  font-size: 12px;
}

.remove-file {
  background: none;
  border: none;
  background-color: red;
  color: white;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  top: -10px;
  right:-10px;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
.datePicker {
  width: 100%;
}
.date-input {
  width: 100%;
  box-sizing: border-box;
}
.datePickerContainer {
  position: relative;
  width: 100%;
}



.datePickerContainer::after {
  content: "\f133"; /* Unicode for calendar icon in Font Awesome */
  font-family: "Font Awesome 5 Free"; /* Ensure you're using the correct Font Awesome version */
  font-weight: 900; /* For solid icons */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #aaa;
}





/* Customize the react-select components further if needed */



@media (max-width: 768px){
  .form-container {
    
    padding: 20px;
    flex-direction: column;
   align-items: center;
   font-size: 0.8em;
  }
  .steps {
    width: 90%;
    text-align: left;
  }
  .form {
    width:97%;
    margin: 25px 0;
   
  }
  .upload-box{
    height: 250px;
  }
}