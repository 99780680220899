.quote-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.quote-modal {
    width: 60%;
    height: 510px; /* Give a fixed height to the modal */
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    display: flex;
    justify-content: flex-end; /* Push the form to the right */
    position: relative;
    background-color: rgb(239, 239, 239);
}

.quote-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.quote-modal-content {
    display: flex;
    flex-direction: column;
    width: 50%; /* Form container takes 50% of the modal width */
    padding: 20px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
}

.quote-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.quote-form-container h2 {
    font-size: 1.2rem;
    margin-bottom: 5px;
    text-align: left;
    width: 100%;
    color: #e63946;
}

.quote-form-container p {
    margin: 5px 0 15px 0;
    text-align: left;
    font-size: 0.8rem;
    width: 100%;
}

.quote-form-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.quote-form-container input,
.quote-form-container textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: none;
    border-radius: 25px;
    font-size: 1rem;
}

.quote-form-container textarea {
    height: 120px;
    resize: none;
}

.quote-form-container button {
    background-color: #e63946;
    color: white;
    padding: 12px 15px;
    border: none;
    border-radius: 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
}

.quote-form-container button:hover {
    background-color: #d62828;
}

@media (max-width: 768px) {
    .quote-modal {
        width: 90%;
        background-position: -185px 20px;
    }

    .quote-modal-content {
        width: 85%;
    }
}
