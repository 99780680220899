.Service-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
}
.Services{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.services-section {
    padding: 40px;
    background-color: #fff;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 88%;
  }
  
  .services-subtitle {
    color: #d4007d; /* This color matches the one shown in the screenshot */
    font-size: 1.2em;
    margin: 1px 0;
  }
  
  .services-title {
    font-size: 2.5em;
    font-weight: bold;
    margin: 1px 0;
  }
  
  .services-description {
    color: #555;
    font-size: 12px;
    line-height: 1.6em;
    max-width: 600px;
  }
  .services-headings{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
   
  }
  
  .Service-page .service-card {
    
    height: 450px;
    

}
.service-banner-text{
  width: 100%;
  position: relative;
}
.service-banner-text span{
  font-size: 45px;
  position: absolute;
  font-weight: 600;
  bottom: 5%;
  left: 5%;
  color: white;
}
/* Service Card Animations */
.service-card {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.7s ease-out, transform 0.7s ease-out;
}

.service-card.fade-in {
  opacity: 1;
  transform: translateX(0);
}

/* Services Section Animation */
.services-section {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.services-section.slide-in {
  opacity: 1;
  transform: translateY(0);
}



@media (max-width:768px) {
  .services-section{
    flex-direction: column;
  }
.services-headings{
  width: 100%;
}
.service-banner-text span {
  font-size: 30px;
  
}


}