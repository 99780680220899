/* AskOurExperts.css */

.ask-experts-container {
  display: flex;
  background-color: rgb(248, 246, 246);
  border-radius: 10px;
  padding: 60px 0;
  margin: auto;
  align-items: center;
  width: 100%;
  justify-content: center;
  opacity: 0;
  transform: translateX(-100px);
  transition: all 0.6s ease-out;
}

.ask-experts-container.animate {
  opacity: 1;
  transform: translateX(0);
}

.ask-experts-image {
  display: flex;
  justify-content: center;
  width: 45%;
}

.ask-experts-photo {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.ask-experts-content {
  padding: 24px;
  background-color: #ffdc89;
  border-radius: 0 20px 0 20px;
  margin-left: -85px;
  width: 30%;
  text-align: left;
}

.ask-experts-title {
  font-size: 2rem;
  font-weight: 600;
  color: #374045;
  margin-top: 0;
  margin-bottom: 15px;
}

.ask-experts-description {
  font-size: 1.1rem;
  color: #5f6368;
  margin-bottom: 20px;
  line-height: 160%;
}

.btn-section{
  width: 100%;
  text-align: right;
}

.ask-experts-button {
  background-color: #374045;
  color: #ffffff;
  padding: 15px 25px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1.2rem;
  width: 40%;
  transition: background-color 0.3s ease;
}

.ask-experts-button:hover {
  background-color: #2c343b;
}

/* Add responsive CSS */
@media (max-width: 768px) {
  .ask-experts-container {
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transform: translateX(-100px);
    transition: all 0.6s ease-out;
  }

  .ask-experts-container.animate {
    opacity: 1;
    transform: translateX(0);
  }

  .ask-experts-image, .ask-experts-content {
    width: 100%;
  }

  .ask-experts-photo {
    width: 100%;
    height: auto;
    border-radius: 0;
  }

  .ask-experts-content {
    padding: 20px;
    margin-left: 0;
    margin-top: -40px;
    width: 80%;
  }

  .ask-experts-title {
    font-size: 1.5rem;
  }

  .ask-experts-description {
    font-size: 0.9rem;
  }

  .ask-experts-button {
    font-size: 1rem;
    width: 45%;
  }
}
