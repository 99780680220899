.terms-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
}


.terms-content {
    margin: 30px 0;
    width: 60%;
    text-align: left;
}

.terms-content h3 {
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: 0;
    color: #840442;
}

.terms-content p {
    font-size: 0.9em;
    color: rgb(127, 127, 127);
    line-height: 1.8;

}
.terms-content a{
    text-decoration: none;
    font-weight: bold;
    color: rgb(99, 98, 98);
}

@import url(https://themes.googleusercontent.com/fonts/css?kit=1BVhQjsg_ddXeTZ1N5YwRA);

.terms-content ol {
    margin: 0;
    padding: 0;
}

.terms-content table td,
.terms-content table th {
    padding: 0;
}

.terms-content .c7 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
}

.terms-content .c1 {
    color: #29334c;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
}

.terms-content .c5 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
}

.terms-content .c2 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 2.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
}

.terms-content .c9 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 2.0;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.terms-content .c0 {
    color: #373a3c;
    font-weight: 700;
}

.terms-content .c3 {
    color: #373a3c;
    font-weight: 400;
}

.terms-content .c6 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt;
}

.terms-content .c4 {
    vertical-align: super;
}

.terms-content .c8 {
    height: 12pt;
}

.terms-content .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 28pt;
    padding-bottom: 4pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.terms-content .subtitle {
    padding-top: 0pt;
    color: #595959;
    font-size: 14pt;
    padding-bottom: 8pt;
    line-height: 1.1583333333333334;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.terms-content li {
    color: #000000;
    font-size: 12pt;
}

.terms-content p {
    margin: 0;
    color: #000000;
    font-size: 12pt;
}

.terms-content h1 {
    padding-top: 18pt;
    color: #0f4761;
    font-size: 20pt;
    padding-bottom: 4pt;
    line-height: 1.1583333333333334;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.terms-content h2 {
    padding-top: 8pt;
    color: #0f4761;
    font-size: 16pt;
    padding-bottom: 4pt;
    line-height: 1.1583333333333334;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.terms-content h3 {
    padding-top: 8pt;
    color: #0f4761;
    font-size: 14pt;
    padding-bottom: 4pt;
    line-height: 1.1583333333333334;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.terms-content h4 {
    padding-top: 4pt;
    color: #0f4761;
    font-size: 12pt;
    padding-bottom: 2pt;
    line-height: 1.1583333333333334;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.terms-content h5 {
    padding-top: 4pt;
    color: #0f4761;
    font-size: 12pt;
    padding-bottom: 2pt;
    line-height: 1.1583333333333334;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.terms-content h6 {
    padding-top: 2pt;
    color: #595959;
    font-size: 12pt;
    padding-bottom: 0pt;
    line-height: 1.1583333333333334;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
}
.privacy-policy ol {
    margin: 0;
    padding: 0;
}

.privacy-policy table td,
.privacy-policy table th {
    padding: 0;
}

.privacy-policy .c0 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 2.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
    height: 12pt;
}

.privacy-policy .c3 {
    color: #212529;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
}

.privacy-policy .c5 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
}

.privacy-policy .c2 {
    color: #212529;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
}

.privacy-policy .c7 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 2.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
}

.privacy-policy .c6 {
    padding: 0;
   
    line-height: 2.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
}

.privacy-policy .c1 {
    padding-top: 8pt;
    padding-bottom: 0;
    line-height: 2.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
}

.privacy-policy .c8 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt;
}

.privacy-policy .c4 {
    height: 12pt;
}

.privacy-policy .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 28pt;
    padding-bottom: 4pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.privacy-policy .subtitle {
    padding-top: 0pt;
    color: #595959;
    font-size: 14pt;
    padding-bottom: 8pt;
    line-height: 1.1583333333333334;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.privacy-policy li {
    color: #000000;
    font-size: 12pt;
}

.privacy-policy p {
    margin: 0;
    color: #000000;
    font-size: 12pt;
}

.privacy-policy h1 {
    padding-top: 18pt;
    color: #0f4761;
    font-size: 20pt;
    padding-bottom: 4pt;
    line-height: 1.1583333333333334;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.privacy-policy h2 {
    padding-top: 8pt;
    color: #0f4761;
    font-size: 16pt;
    padding-bottom: 4pt;
    line-height: 1.1583333333333334;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.privacy-policy h3 {
    padding-top: 8pt;
    color: #0f4761;
    font-size: 14pt;
    padding-bottom: 4pt;
    line-height: 1.1583333333333334;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.privacy-policy h4 {
    padding-top: 4pt;
    color: #0f4761;
    font-size: 12pt;
    padding-bottom: 2pt;
    line-height: 1.1583333333333334;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.privacy-policy h5 {
    padding-top: 4pt;
    color: #0f4761;
    font-size: 12pt;
    padding-bottom: 2pt;
    line-height: 1.1583333333333334;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.privacy-policy h6 {
    padding-top: 2pt;
    color: #595959;
    font-size: 12pt;
    padding-bottom: 0pt;
    line-height: 1.1583333333333334;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.terms-content h5{
    margin: 10px 0 5px;
}
@media (max-width: 768px) {

.terms-content{
    width: 95%;
}

}