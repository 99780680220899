/* src/CSS/Banner.css */

.slider-container {
    position: relative;
    height: 620px;
    overflow: hidden;
}

.slide {
    position: relative;
    height: 550px; /* Ensure slide height matches image height */
}

.slide-image {
    width: 100%;
    height: 550px; /* Set fixed height for consistency */
    object-fit: cover;
    display: block;
    margin: 0 auto; /* Center the image horizontally */
    transition: transform 0.5s ease-in-out; /* Smooth transition for non-animated states */
}

/* Zoom-in animation class */
.zoom-animation {
    animation: zoomIn 8s linear forwards; /* Duration matches autoplaySpeed */
}

/* Keyframes for zoom-in effect */
@keyframes zoomIn {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.2);
    }
}

/* Overlay positioned over the image only */
.slider-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 555px; /* Match the image height */
    background: rgba(0, 0, 0, 0.296); /* Semi-transparent overlay */
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align content to the start (left) */
    box-sizing: border-box;
    z-index: 2;
}

/* Content inside the overlay */
.overlay-content {
    position: absolute;
    left: 13%;
    top: 20%;
    color: white;
    max-width: 50%; /* Control width of text content */
    text-align: left;
}

.overlay-content h1 {
    font-size: 2.9rem;
    margin-bottom: 10px;
}

.overlay-content p {
    font-size: 1rem;
    margin-bottom: 25px;
}

.overlay-content button {
    padding: 12px 25px;
    font-size: 1rem;
    background-color: #a7185d;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.overlay-content button:hover {
    background-color: #a44472;
}

.boxes-container {
    position: absolute;
    bottom: 1%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    z-index: 3;
}

.box {
    width: 340px;
    height: 150px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-container {
    width: 30%;
}

.text-container {
    width: 70%;
}

.text-container h2,
.text-container h4 {
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;
}

.text-container h2 {
    font-size: 1.3rem;
}

.text-container h4 {
    letter-spacing: 0.004rem;
    font-size: 1rem;
}

.box img {
    height: 50px;
    padding: 15px;
    border-radius: 50%;
    object-fit: cover;
}

.box1 {
    background-color: #9a054d;
    border-radius: 5px 0 0 5px;
}

.box2 {
    background-color: #9f245f;
}

.box3 {
    background-color: #a44472;
    border-radius: 0 5px 5px 0;
}

.box1 img {
    background-color: #ce0868;
}

.box2 img {
    background-color: #c64282;
}

.box3 img {
    background-color: #95084c;
}
/* Overlay Animation */
.slider-container .overlay {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.9s ease-out, transform 0.9s ease-out;
  }
  
  .slider-container .overlay.fade-up {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Box Animations */
  .box {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.9s ease-out, transform 0.9s ease-out;
  }
  
  .box.fade-up {
    opacity: 1;
    transform: translateY(0);
  }
  
/* Media Queries */
@media (max-width: 768px) {
    .slider-container {
        height: 110vh;
    }

    .slide {
        height: 71vh; /* Adjust slide height for smaller screens */
    }

    .slide-image {
        height: 100%; /* Adjust image height for smaller screens */
    }
 
    .slider-container .overlay {
        height: 100%; /* Match image height */
        padding-left: 5%; /* Adjust padding for smaller screens */
    }

    .overlay-content {
        max-width: 85%; /* Adjust width */
        left: 9%;
        top: 16%;
    }

    .overlay-content h1 {
        font-size: 2rem;
    }

    .overlay-content p {
        font-size: 1rem;
    }

    .boxes-container {
        bottom: 1%;
        flex-direction: column;
        width: 85%;
    }

    .box {
        width: 100%;
        height: 130px;
    }

    .text-container h2 {
        font-size: 1.2rem;
    }

    .text-container h4 {
        font-size: 0.9rem;
    }
}

@media (max-width: 378px){
    .slider-container {
        height: 120vh;
    }

    .slide {
        height: 80vh; /* Adjust slide height for smaller screens */
    }
    

    .overlay-content h1 {
        font-size: 1.5rem;
    }

    .overlay-content p {
        font-size: 0.9rem;
    }

    .boxes-container {
        bottom: 2%;
        flex-direction: column;
        align-items: center;
    }

    .box {
        width: 90%; /* Make boxes full width on very small screens */
        height: 12vh;
        padding: 15px;
    }

    .text-container h2 {
        font-size: 1rem;
    }

    .text-container h4 {
        font-size: 0.8rem;
    }

    .box img {
        height: 36px;
        padding: 10px;
    }
}
