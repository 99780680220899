.sidebar {
    width: 7%;  
    background-color: rgba(255, 255, 255);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);  
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 30px;
  }
  .side-bar-overlay {
   
    
    width: 100%;
    height: 100%;
   
    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    gap: 30px;
  }
  .sidebar .logo-box{
    margin-bottom: 20px ;
    --logo-size: 0.7; 
    color: white !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .doctor-dashboard .sidebar .logo-box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .doctor-dashboard .sidebar{
    width: 7%;
  }
  
  .doctor-dashboard .nav-item{
    flex-direction: row;
    font-size: 1.1rem;
    font-weight: 400;
    padding: 15px 0;
    color: hsl(229 11% 51%);
    width: 60%;
  }
  .profile-section {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  
  .doctor-name {
    margin: 0;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;

  }
  
  .status-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    gap: 25px;
  }
  
  .status-toggle input[type="checkbox"] {
    display: none;
  }
  
  .status-toggle .slider {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    background-color: #ccc;
    border-radius: 12px;
    cursor: pointer;
  }
  
  .status-toggle input:checked + .slider {
    background-color: #4caf50;
  }
  
  .status-toggle .slider::before {
    position: absolute;
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: white;
    top: 3px;
    left: 3px;
    transition: 0.4s;
  }
  
  .status-toggle input:checked + .slider::before {
    transform: translateX(26px);
  }
  
  .nav-items {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 25px;
    
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: rgb(43, 43, 43);
    text-decoration: none;
    font-size: 1.3rem;
    transition: background-color 0.3s;
    justify-content: center;
    width: 75%;
    margin: 10px 0;
    font-weight: 400;
    gap: 5px;
    background: none;
    border: none;
    outline: none;
    border-radius: 30px;
  }
  
  
  
  .nav-item i {
    font-size: 1.3rem;
    
  }
  .nav-side-active{
  }
  .nav-side-active i{
     
    font-size: 1.3rem;
  }
  .nav-item i {
    margin-right: 0; /* Space between icon and text */
  }
  
  /* Initially, hide the text if not open */
  .sidebar:not(.open) .nav-item span {
    display: none;
  }
  
  /* On hover (when sidebar is closed), show tooltip-like text or inline text */
  .sidebar:not(.open) .nav-item:hover span {
    display: inline-block;
    position: absolute;
    background: #fff;
    color: #333;
    padding: 0.5rem;
    margin-left: 2.5rem;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(0,0,0,0.1);
    white-space: nowrap;
    z-index: 10;
  }
  
  /* When sidebar is open, show the text labels inline */
  .sidebar.open .nav-item span {
    display: inline-block;
  }
  
  .need-help{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 20px 10px;
    background-color: #F6F9FF;
    border-radius: 10px;
    width: 80%;
    margin-top: 80px;
    
  }
  .need-help img{
    width: 80px;
    top: -20%;
  }
  .need-help h4{
    margin: 15px 0 0;
    margin-bottom: 2px;

  }
  .need-help span{
    color: #6c7488;
    font-weight: 600;
  }
  .need-help p{
    color: #C6CBD9;
    font-size: 0.8rem;
    margin: 0;
  }
  .need-help a{
    text-decoration: none;
    color: #6c7488;
    font-weight: 600;
  }
  .need-help-icon{
    position: absolute;
  }
  .logout-button{
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1rem;
    width: 75%;
    font-weight: 600;
    color: rgb(43, 43, 43);
  }
  .nav-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .doctorSidebar2 {
  width: 28%;
  }

  .doctorSidebar2__content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
 



  .doctorSidebar2__medicine-section,
  .doctorSidebar2__document-section{

    width: 92%;
    margin-bottom: 30px;
  }
  .doctorSidebar2__message-section {
   
   padding: 20px;
    width: 100%;
 
    
  }
  
  .doctorSidebar2__document-section h3{
  text-align: left;
  margin:0;
  }

  .doctorSidebar2__patients-stats, 
  .doctorSidebar2__ratings-stats, 
  .doctorSidebar2__medicine-stats {
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 15px;


  }
  .doctorSidebar2__patients-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    width: 90%;

  }
  



  .doctorSidebar2__stat-item {
    display: flex;
    align-items: center;
   border-radius: 20px;
  }

  .doctorSidebar2__icon-container {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
   
  }
.doctorSidebar2-box{
  display: flex;
  flex-direction: column;
}



  .doctorSidebar2__stat-label {
    color: #747070;
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .doctorSidebar2__stat-value {
    font-weight:700;
    font-size: 1.3em;
    color: #8197ca;

  }
  .doctorSidebar2__stat-value-2{
    color: rgb(252, 3, 144);
  }
  .doctorSidebar2__stat-value-3{
  color: orange;


  }
  .doctorSidebar2__stat-value-4{
    color: red;

  }

  .doctorSidebar2__document-list {
    display: flex;
    gap: 16px;
  
  }
  .doctorSidebar2__document-list p{
    margin: 2px 0;
    text-align: left;
    font-size: 0.8rem;
    font-weight: 600;
    color: #999898;
  }

  .doctorSidebar2__document-item {
    flex: 1;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.101);
    border-radius: 8px;
    box-shadow: 0 0 8px #0000000d;
    flex: 1 1;
    padding: 15px;
    
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
  }

  .doctorSidebar2__document-item h4 {
    color: #000;
    font-size: 15px;
    margin: 0 0 4px;
    width: 100%;
  }

  .doctorSidebar2__document-item p {
    color: #6c757d;
    font-size: 11px;
    margin: 0 0 4px;
    width: 100%;
    text-align: start;
 
  }

  .doctorSidebar2__document-item button {
    background-color:#faa293;
    color: white;
    border: none;
    padding: 4px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  .doctorSidebar2__message_heading{
    display: flex;
    align-items: center;
  }

  .doctorSidebar2__unread-count {
    font-size: 0.875rem;
    color: #6b7280;
    margin: 0px 0;
    
  }
  .doctorSidebar2__message-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .doctorSidebar2__message-avatar {
    padding: 7px;
    color: #7489B6; /* Icon color */
    background-color: #d4d7de;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .doctorSidebar2__message-content {
    flex: 1;
    padding: 10px;
  }
  
  .doctorSidebar2__message-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .doctorSidebar2__sender-name {
    font-weight: bold;
    margin: 0;
    font-size: 0.9rem;
  }
  
  .doctorSidebar2__message-datetime {
    font-size: 0.8em;
    color: #999;
    margin: 0;
  }
  
  .doctorSidebar2__message-preview {
    margin: 0;
    color: #5e5d5d;
    text-align: left;
    font-size: 0.7rem;
  }
  
  .doctorSidebar2__message-preview a {
    color: #007bff;
    text-decoration: none;
  }
  
  .doctorSidebar2__message-preview a:hover {
    text-decoration: underline;
  }
  .message-chat {
    background-color: #D5DBE9; /* Green */
  }
  
  .message-missed_call {
    background-color: #D5DBE9; /* Red */
  }
  
  .message-file_upload {
    background-color: #D5DBE9; /* Blue */
  }
  
  @media (max-width: 768px) {

    .doctorSidebar2 {
      width: 100%;
  }

    .sidebar.open .nav-item span {
      display: inline-block; /* On mobile when sidebar is open, text is visible */
    }
    
    .sidebar:not(.open) .nav-item span {
      display: none; /* On mobile when sidebar is closed, just show icons */
    }
    .doctor-dashboard .sidebar {
      width: 250px;
  }
  .nav-item{
    flex-direction: row;
    align-items: center;
    justify-content: start;
  }
    /* If you want to show text on long-press or different interactions, 
       consider JS-based toggles instead of hover. */
  }