.patient-profile-container {
    margin: auto;
    padding: 20px 0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    gap: 20px;
  }
  
  .patient-profile-container .profile-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    
  }
  
  .patient-profile-container .profile-initial {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #007bff;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin-right: 15px;
  }
  .patient-profile-container .profile-info{
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .patient-info-details{
    display: flex;
    flex-direction: column;
    align-items:start;
    gap: 5px;
    
  }
  .patient-profile-container .profile-info h2 {
    margin: 0;
    font-size: 24px;
  }
  
  .patient-profile-container .profile-info p {
    margin: 0;
    color: #777;
  }
  
 
  

  .patient-profile-container h3 {
    margin-bottom: 5px;
    font-size: 18px;
  }
  
  .patient-profile-container p, 
  .patient-profile-container ul {
    margin: 0;
    font-size: 16px;
    text-align: start;
  }
  
  .patient-profile-container ul {
    padding-left: 20px;
  }
  
  .patient-profile-container ul li {
    margin-bottom: 5px;
  }
 /* Demographics Container */
.demographics {
  border: none;
  width: 100%;
  margin: 0;
  padding: 1rem 0;
}

/* Row Layout */
.demo-row {
  display: flex;
  justify-content: space-between;
  margin: 3rem 0;
  width: 100%;
  gap: 2rem;
}

/* Individual Item Cards */
.demo-item {
  display: flex;
  flex-direction: column;
  width: 35%;
  position: relative;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(12px);
  border-radius: 24px;
  padding: 2rem 1.5rem;
  padding-top: 3rem;
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.demo-item:hover {
  transform: translateY(-5px);
  box-shadow: 
    0 12px 40px rgba(0, 0, 0, 0.15),
    inset 0 0 0 1px rgba(255, 255, 255, 0.2);
}

/* Icon Container */
.demo-item .patient-report-icon {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(8px);
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 
    0 4px 16px rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.demo-item:hover .patient-report-icon {
  transform: translate(-50%, -50%) scale(1.1);
  background: rgba(255, 255, 255, 0.3);
}

/* Icon Image */
.demo-item .patient-report-icon img {
  width: 60%;
  height: 60%;
  object-fit: contain;
  opacity: 0.8;
  transition: all 0.3s ease;
}

.demo-item:hover .patient-report-icon img {
  opacity: 1;
}

/* Labels and Values */
.label {
  font-weight: 600;
  font-size: 0.875rem;
  color: rgba(102, 102, 102, 0.8);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 0.5rem;
  text-align: center;
}

.value {
  font-size: 1.25rem;
  color: #333;
  font-weight: 500;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .demographics {
    width: 95%;
    padding: 0.5rem;
  }

  .demo-row {
    flex-direction: row;
    margin: 3rem 0;
    gap: 1rem;
  }

  .demo-item {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
}

/* Animation for Initial Load */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.demo-item {
  animation: fadeInUp 0.6s ease forwards;
}

.demo-row:nth-child(2) .demo-item {
  animation-delay: 0.2s;
}
  .vitals {
    display: flex;
    justify-content: space-around;
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    width: 95%;
  }
  
  .vital-card {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .icon {
    font-size: 24px;
    color: #007bff;
    margin-right: 15px;
  }
  
  .vital-info h3 {
    margin: 0;
    font-size: 14px;
    color: #666;
  }
  
  .vital-info p {
    margin: 5px 0 0;
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }
  .patient-notes {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    font-family: Arial, sans-serif;
  }
  
  .notes-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .notes-header h3 {
    margin: 0;
    color: #333;
    font-size: 18px;
  }
  
  .notes-actions {
    display: flex;
    gap: 15px;
  }
  
  .action-icon {
    color: #fff;
    background-color: #007bff;
    border: none;
    outline: none;
    font-size: 16px;
    cursor: pointer;
    padding: 20px 0;
    width: 100%;
    border-radius: 25px;
  }
  
  .notes-content {
    font-size: 14px;
    color: #333;
  }
  
  .notes-date {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .notes-text {
    line-height: 1.4;
    margin: 0;
  }
  .lab-results {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    font-family: Arial, sans-serif;
  }
  
  .lab-results-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .lab-results-header h3 {
    margin: 0;
    color: #333;
    font-size: 18px;
  }
  
  .menu-icon {
    color: #888;
    font-size: 16px;
    cursor: pointer;
  }
  
  .results-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .result-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .result-item:last-child {
    border-bottom: none;
  }
  
  .file-icon {
    color: #4a90e2;
    font-size: 18px;
    margin-right: 15px;
  }
  
  .result-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
  }
  
  .result-name {
    color: #333;
  }
  
  .result-date {
    color: #888;
  }
 

/* Health Metrics */
.patient-health-metrics {
  background-color: rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 12px;
  max-width: 1000px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  width: 95%;

}

.patient-health-metrics-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.patient-health-metrics-header h2 {
  margin: 0;
  color: #333;
}

.patient-see-all-button {
  color: #4a90e2;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
}

.patient-health-metrics-grid {
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.patient-health-metrics-grid::-webkit-scrollbar {
  display: none; /* WebKit */
}

.patient-health-metrics-card {
  flex: 0 0 auto;
  min-width: 200px;
  background-color: rgba(255, 255, 255, 0.068);
  backdrop-filter: blur(10px);
  padding: 16px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(251, 250, 250, 0.032);
}

.patient-health-metrics-info {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
}

.patient-health-metrics-title {
  color: #666;
  font-size: 0.7rem;
}

.patient-health-metrics-value {
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
}

.patient-health-metrics-icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.patient-health-metrics-icon svg {
  width: 100%;
  height: 100%;
  color: #666;
}

/* Patient Notes */
.patient-notes {
  background-color: rgba(255, 255, 255, 0.206);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  width: 95%;

}

.patient-notes-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.patient-notes-header h3 {
  margin: 0;
  color: #333;
}

.patient-notes-actions {
  position: relative;
}

.patient-action-icon {
  font-size: 20px;
  color: #6c757d;
}

.patient-menu-dropdown {
  position: absolute;
  top: 25px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  display: flex;
  flex-direction: column;
}

.patient-menu-dropdown button {
  padding: 10px 20px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  width: 100%;
}

.patient-menu-dropdown button:hover {
  background-color: #f0f0f0;
}

.patient-notes-content {
  padding: 10px 0;
}

.patient-notes-text {
  margin: 0;
  color: #555;
}

/* Medical History */
.patient-medical-history {
  background-color: rgba(255, 255, 255, 0.206);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  width: 95%;

}

.patient-history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 600;
  margin-bottom: 10px;
}

.patient-history-header h2 {
  font-size: 18px;
  color: #333;
  margin: 0;
}

.patient-history-content {
  display: flex;
  gap: 20px;
  width: 100%;
  overflow-x: auto;
  padding: 0;
}

.patient-history-content::-webkit-scrollbar {
  height: 6px;
}

.patient-history-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

.patient-history-item {
  background-color: rgba(255, 255, 255, 0);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 15px;
  text-align: left;
  flex: 0 0 250px;
  box-shadow: 0px 0px 20px rgba(251, 250, 250, 0.032);
}

.patient-history-date {
  font-size: 12px;
  color: #6c757d;
  margin: 0;
  margin-bottom: 6px;
}

.patient-history-title {
  font-size: 18px;
  color: #000;
  margin: 0;
  margin-bottom: 6px;
}

.patient-history-description {
  font-size: 14px;
  color: #6c757d;
  margin: 0;
  font-weight: 300;
}

/* Medical Reports */
.patient-medical-reports {
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 95%;

}

.patient-reports-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.patient-reports-header h2 {
  font-size: 18px;
  color: #333;
  margin: 0;
}

.patient-reports-content {
  display: flex;
  gap: 20px;
  width: 100%;
  overflow-x: auto;
  padding: 27px 0;
}

.patient-reports-content::-webkit-scrollbar {
  height: 6px;
}

.patient-reports-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

.patient-report-item {
  background-color: transparent;
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 15px;
  flex: 0 0 170px;
  box-sizing: border-box;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #ddd;
}

.patient-report-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 5px;
  width: 100%;
  position: relative;
}

.patient-report-icon {

  background-color: #fff5;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.patient-report-icon svg {
  font-size: 30px;
}

.patient-report-name {
  font-size: 1rem;
  color: #000;
  margin: 0;
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.patient-report-date {
  font-size: 0.7rem;
  color: #6c757d;
  margin: 0;
  margin-top: 4px;
}

/* Diagnoses Section */
.patient-diagnoses-section {
  background-color: rgba(255, 255, 255, 0.206);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  width: 95%;

}

.patient-diagnoses-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.patient-diagnoses-header h2 {
  font-size: 18px;
  color: #333;
  margin: 0;
}

.patient-add-diagnosis-icon {
  font-size: 20px;
  color: #6c757d;
}

.patient-diagnoses-content {
  width: 100%;
  padding: 10px 0;
}

.patient-diagnosis-item {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
}

.patient-diagnosis-item p {
  margin: 5px 0;
}

.patient-diagnosis-item a {
  color: #4a90e2;
  text-decoration: none;
}

.patient-diagnosis-item a:hover {
  text-decoration: underline;
}

/* Not Found Message */
.patient-not-found-message {
  width: 100%;
  color: #929292 !important;
  text-align: center;
}

/* Responsive */
@media (max-width: 768px) {
  .patient-health-metrics-grid {
    /* Keep flex-wrap: nowrap to maintain horizontal scrolling */
  }

  .patient-health-metrics-card {
    min-width: 180px;
  }

  .patient-history-item,
  .patient-report-item {
    flex: 0 0 150px;
  }
}
