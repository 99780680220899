.patient-details-container {
  width: 40%;
  margin: 60px auto;
  padding: 60px 20px;
  background-color: transparent;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #ffffff42;
  border-radius: 20px;
}

.patient-details-header {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 95%;
}

.patient-details-header h2 {
  margin: 0;
  font-size: 2rem;
  font-weight: bold;
}

.patient-details-form {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 20px;
}

.patient-details-form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.patient-details-label {
  font-size: 1rem;
  color: rgba(43, 43, 43);
  margin-bottom: 5px;
  text-align: left;
}

.patient-details-input {
  width: 100%;
  font-size: 0.9rem;
  border-radius: 4px;
  background-color:white;
  outline: none;
  transition: border-color 0.2s ease;
  margin: 0;
  padding: 12px 15px;
  border-radius: 15px;
  box-sizing: border-box;
}

.patient-details-input:focus {
  border-color: #888;
}

.patient-details-input[disabled] {
  background-color: white;
  color: #949393;
  cursor: not-allowed;
}

.patient-details-edit-button,
.patient-details-save-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 15px;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  font-size: 1rem;
}

.patient-details-edit-button:hover,
.patient-details-save-button:hover {
  background-color: #0056b3;
}

.patient-details-loading {
  text-align: center;
  font-size: 1.2rem;
  color: #888;
}
@media (max-width: 768px) {
  .patient-details-container {
    width: 95%;
   
    padding: 60px 0px;
    
}
}