.ServiceDetails {
    padding: 20px;
    
}

.service-header {
    text-align: center;
    margin-bottom: 30px;
}

.service-details-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.detail-card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
@media (max-width:768px) {
    .ServiceDetails {
        padding: 20px 0;
        font-size: 0.8em;
    }
    
    .service-header {
     
        font-size: 0.8em;
    }
    
    
    
    .detail-card {
      
        padding: 20px 0;
       
    }
  
  
  }