.registration-container {
    display: flex;
   align-items: center;
   justify-content: center;
   height: 100vh;
   background-color: #ebeaea;
   position: relative;
}

.login-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #ebeaea;
    position: relative;
}

.logo-placeholder {
   width: 95%;
   height: 15%;
   align-items: center;
   display: flex;
    
}
.form-input-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
height: 73%;
}
.logo-placeholder .logo-box{
    --logo-size: 0.8;
}
.registration-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 87%;
    width: 100%;
    margin-top: 0px;
    overflow-y: auto;
   
}
/* Container for the testimonial section */
.testimonial-section {
    position: relative;
    width: 45%;
   /* Full viewport height */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 92%;
    border-radius: 0 20px 20px 0;
  }
  
  /* Static background image */
  .testimonial-image-container {
   
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  .testimonial-static-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
   
  }
  
  /* Slider container for quotes */
  .testimonial-slider-container {
    position: absolute;
    bottom: 5%;
    left: 10%;
    z-index: 2;
    width: 80%; /* Adjust the width as needed */
  }
  
  /* Styling for individual quote box */
  .testimonial-quote-box {
    background: rgba(255, 255, 255, 0.6);
    -webkit-backdrop-filter: blur(10px); /* Translucent white background */
    backdrop-filter: blur(10px); /* Glassy effect */
    padding: 20px;
    height: 170px;
    
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    position: relative;
  }
  
  /* Quote text styling */
  .testimonial-quote {
    font-size: 1.2rem;
    
    color: #fff;
    margin-bottom: 10px;
  }
  
  /* Author and profession text styling */
  .testimonial-author {
    
    color: #fff;
    
    display: flex;
    flex-direction: column;
  }
  .testimonial-author-name{
    font-size: 1.11rem;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .testimonial-author-profession{
font-size: 0.9rem;
  }
  
  /* Customize arrows */
  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 3;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    
    border-radius: 50%;
   height: 40px;
   width: 40px;
    border: 1px solid white;
    transition: background-color 0.3s ease;
  }
  
  .arrow:hover {
    background-color: rgba(255, 255, 255, 1);
    color: black;
  }
  
  .prev {
    right: 65px;
  }
  
  .next {
    right: 10px;
  }
  
.error-class{
  width: 65%;
  color: red;
  font-size: 0.9em;
  margin: 0;
  margin-bottom: 5px;
  text-align: left;
  
}


.form-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-color: #fff;
    width: 33%;
    position: relative;
    height: 92%;
    border-radius: 20px 0 0 20px;
  
}
.login-container .form-section{
  justify-content: space-between;
}

.form-section h3 {
    font-size: 24px;
    margin: 0;
    margin-bottom: 5px;
    text-align: start;
    width: 65%;
    font-weight: 600;
}

.form-subheading {
    font-size: 13px;
    color: #767575;
    margin: 0;
    margin-bottom: 15px;
    text-align: start;
    width: 65%;
}
.profile-photo-container-box{
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-photo-container {
  position: relative;
  width: 100px;
  height: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
}

.profile-photo-input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  border-radius: 50%;
}

.profile-photo-placeholder {
  background-color: rgb(246, 246, 246);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
}

.profile-photo-placeholder img {
  width: 100%;
  height: 100%; /* Preserve the aspect ratio of the image */
  object-fit: cover; /* Ensures the entire image is visible without stretching */
  max-height: 100%;
  max-width: 100%;
  border-radius: 50%; /* Keeps the image circular */
}

.profile-photo-placeholder::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30%;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}

.profile-photo-placeholder::after {
  content: "Upload Picture";
  position: absolute;
  bottom: 13px;
  left: 0;
  right: 0;
  color: white;
  font-size: 10px;
  text-align: center;
  display: none;
}

.profile-photo-container:hover .profile-photo-placeholder::before,
.profile-photo-container:hover .profile-photo-placeholder::after {
  display: block;
}

.profile-photo-preview {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the entire preview image is visible without stretching */
  max-height: 100%;
  max-width: 100%;
  border-radius: 50%; /* Keeps the preview circular */
}



.form-input {
    margin: 0;
    margin-bottom: 15px;
    padding: 14px 4px;
    border: none;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    border-radius: 0;
    width: 65%;
}
.forgot-mail-field {
  margin-top: 20px;
}
.form-input:focus {
    outline: none;
    border-bottom-color: #007bff;
}

.form-end-box{
 
  
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
   

}
.submit-button {
    padding: 10px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 68%;
    font-size: 15px;
    margin-top: 20px;
}


.submit-button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
}
.form-end{
    font-size: 12px;
    color: #767575;
    
    text-align: center;
    width: 65%;
    margin-bottom: 25px;
}
.doctorSidebar2__doctor-info{
    padding-left: 10px;
}
.doctorSidebar2__doctor-info span{
   display: flex;
   align-items: center;
   font-size: 1rem;
   font-weight: 700;
}
.forgot-password-doctor{
  text-decoration: none;
  color: black;
  text-align: right;
  font-size: 0.8em;
  width: 65%;
}

.react-select-container {
  width: 66%; /* Set width */
  margin-bottom: 10px;
}

.custom-select__control {
  border: none !important; /* Remove all default borders */
  border-bottom: 1px solid #ddd !important; /* Add only bottom border */
  box-shadow: none !important; /* Remove any box shadow */
  border-radius: 0 !important; /* Remove border radius */
  background-color: transparent !important; /* Ensure no background color */
  min-height: 40px; /* Set the height */
  height: auto !important;
  outline: none !important; /* Remove outline */
  padding: 0 !important;
  


}

.custom-select__value-container {
  display: flex; /* Ensure flex layout */
  align-items: center; /* Vertically center the content */
  padding-left: 1px !important ;
  padding-bottom: 8px !important;
  
}

.custom-select__single-value, 
.custom-select__placeholder {
  font-size: 14px !important; /* Adjust font size */
  color: rgb(104, 104, 104) !important; /* Set placeholder color */
  text-align: left !important; /* Align text to the left */
  margin: 0; /* Remove any default margins */
}

.custom-select__single-value {
  color: #333 !important; /* Set color for the selected value */
}

.custom-select__dropdown-indicator {
  padding: 0 !important; 
  padding-bottom: 3px !important;/* Remove padding around the arrow */
  margin-right: 0 !important; /* Align arrow to the far right */
}

.custom-select__indicator-separator {
  display: none !important; /* Remove separator line between value and arrow */
}


  /* Responsive slider for mobile */
  @media (max-width: 768px) {
    .testimonial-section {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .logo-placeholder{
        margin: 10px 0 50px ;
      }
      .logo-placeholder .logo-box{
        --logo-size: 0.7;
    }
    .form-section{
        z-index: 5;
        width: 90%;
        height: auto;
        border-radius: 20px 0 20px 0;
    }  

    .form-section h3,.form-subheading,.form-input,.submit-button,.forgot-password-doctor{
        width: 85%;
    }
.registration-container{
height: 110vh;

}
    .testimonial-slider-container {
    display: none;
    }
    .arrow {
      font-size: 18px;
    }
    select.form-input {
     
      width: 88%;
  }
  .react-select-container {
    width: 88%; /* Set width */
   
  }
  .form-section h3{
    font-size: 20px;
  }
  .form-subheading{
    font-size: 11px;
  }
  .profile-photo-placeholder::before,.profile-photo-placeholder::after {
    display: block;
  }
  .error-class{
    width: 85%;
 
    
  }

  }
  