@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
html,body{
  margin: 0;
  padding: 0;
  font-family: "Noto Sans", sans-serif;
  height: 100%;
}
h1, h2 ,h3, h4{
  font-family: "Playfair Display", serif;
  font-weight: 100;
}
/* General DatePicker Styling */
.react-datepicker {
  font-family: 'Roboto', sans-serif;
  border: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
}

/* Purple Header */
.react-datepicker__header {
  background-color: #6200ee; /* Purple Header */
  color: #fff;
  border-bottom: none;
}

/* Selected Date Styling */
.react-datepicker__day--selected {
  background-color: #6200ee;
  color: #fff;
  border-radius: 50%;
}

/* Hover Effects */
.react-datepicker__day:hover {
  background-color: #e3d4ff;
  border-radius: 50%;
}

/* Today Highlight */
.react-datepicker__day--today {
  font-weight: bold;
  color: #6200ee;
}

/* Year/Month Dropdowns */
.react-datepicker__current-month,
.react-datepicker__day-name {
  font-weight: 500;
  font-size: 1rem;
  color: #fff;
}

.react-datepicker__navigation {
  top: 16px;
}

/* Month Picker Header */
.react-datepicker__current-month {
  font-size: 1.1rem;
}

/* Active Month/Year Dropdown */
.react-datepicker__month-option--selected_month,
.react-datepicker__year-option--selected_year {
  background-color: #6200ee;
  color: #fff;
}

