.header {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
}

.header-strip {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #840442;
  color: white;
  font-size: 11px;
}

.header-strip .social-icons {
  width: 7%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-strip a i {
  color: white;
  font-size: 13px;
  border-radius: 50%;
  padding: 6px;
  background-color: #ba1665;
  gap: 3px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 70px;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.nav-links a {
  margin: 0 15px;
  text-decoration: none;
  color: #333;
}

.quote-button {
  background-color: #a7185d;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.quote-button:hover {
  background-color: #6a103c ;
  padding: 11px 20px;
}

/* Logo */
:root {
  --logo-size: 0.9; /* You can change this to scale the entire logo */
}

.logo-box {
  display: flex;
  align-items: center;
  font-size: calc(var(--logo-size) * 1rem);
}

.logo-box img {
  width: calc(var(--logo-size) * 90px);
}

.logo h1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 100;
  color: #840442 !important;
  margin: 0;
  font-size: calc(var(--logo-size) * 1rem);
}

.title-we {
  font-size: calc(var(--logo-size) * 50px);
  font-family: 'Gloock', serif;
  font-weight: 100;
}

.title-2 {
  display: flex;
  flex-direction: column;
  margin-left: calc(var(--logo-size) * 5px);
}

.title-2 span {
  font-size: calc(var(--logo-size) * 15px);
  margin: calc(var(--logo-size) * 1px) 0;
  font-weight: 100;
}
/* Hide the menu button on larger screens */
.menu-button {
  display: none;
}
.header-sidebar{
  display: none;
}
.nav-links a{
  position: relative; /* Needed for the pseudo-element positioning */
  transition: color 0.3s ease; /* Optional: If you still want some color transition */
}

/* Remove existing hover color changes if present */
.nav-links a:hover,
.header-sidebar-links a:hover {
  color: #333; /* Ensure color doesn't change on hover */
}

/* Pseudo-element for the underline */
.nav-links a::after,
.header-sidebar-links a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px; /* Thickness of the underline */
  background-color: #840442;; /* Color of the underline */
  left: 0;
  bottom: 0;
  transition: width 0.4s ease; /* Animation for the underline */
}

/* Expand the underline on hover */
.nav-links a:hover::after,
.header-sidebar-links a:hover::after {
  width: 100%;
}
.nav-links a.active::after,
.header-sidebar-links a.active::after {
  width: 100%; /* Keep the underline visible for the active link */
}
.nav-links a.active {
  color: #840442; /* Change to your desired active color */
}
/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .nav-links,
  .quote-button-link {
      display: none;
  }

  .menu-button {
      display: block;
      background: none;
      border: none;
      font-size: 24px;
      cursor: pointer;
      color: #840442;
  }

  .header-container {
      padding: 15px 20px;
  }
  /* Sidebar styles */
.header-sidebar {
  position: fixed;
  top: 0;
  left: -100%; /* Move it completely off-screen */
  width: 250px;
  height: 100%;
  background-color: #840442;
  transition: left 0.3s ease;
  display: flex;
  flex-direction: column;
  padding: 20px;
  z-index: 1000;
}
.header-strip p{
  font-size: 9px;
}
.header-sidebar.open {
  left: 0;
}

.header-sidebar .close-header-sidebar {
  background: none;
  border: none;
  font-size: 24px;
  color: white;
  align-self: flex-end;
  cursor: pointer;
}

.header-sidebar-links {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}

.header-sidebar-links a {
  color: white;
  text-decoration: none;
  margin: 15px 0;
  
  font-size: 18px;
}
.header-sidebar-links .active{
  border-bottom: 1px solid white;
}
/* Backdrop for closing sidebar */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 900;
}

.backdrop.show {
  opacity: 1;
  visibility: visible;
}
.header-strip .social-icons {
 display: none;
}
.logo-box{

  --logo-size: 0.7;
}
}



