.call-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.call-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 2rem;
  border-radius: 10px;
  outline: none;
  width: 90%;
  max-width: 400px;
}

.call-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.call-modal-buttons {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
}

.accept-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.decline-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.accept-button:hover {
  background-color: #45a049;
}

.decline-button:hover {
  background-color: #d32f2f;
}

.call-type-icon {
  margin-bottom: 1rem;
}