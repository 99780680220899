/* src/Components/WhatsAppButton.css */

.whatsapp-button {
    position: fixed;
    bottom:5%;
    right: 3%;
    background-color: #25d366;
    color: white;
    border-radius: 50px;
    text-align: center;
    font-size: 1rem;
    box-shadow: 2px 2px 3px #999;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    transition: background-color 0.3s, transform 0.3s;
    text-decoration: none;
    padding: 10px 20px;
  }
  
  .whatsapp-button:hover {
    background-color: #128c7e;
    transform: scale(1.05);
  }
  
  .whatsapp-button i {
    margin-right: 10px;
    font-size: 2.2rem;
  }
  
 
  