:root {
    --primary-color: #840442;
    --primary-light: #4895ef;
    --secondary-color: #f8f9fa;
    --text-primary: #2b2d42;
    --text-secondary: #6c757d;
    --success-color: #2ec4b6;
    --warning-color: #ff9f1c;
    --danger-color: #e71d36;
    --sidebar-width: 250px;
    --transition-speed: 0.3s;
    --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
    --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);
    --rounded-sm: 8px;
    --rounded-md: 12px;
    --rounded-lg: 16px;
    --font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

/* Container Layout */
.admin-dashboard-container {
    display: flex;
    height: 100vh;
    width: 100%;
}

/* Sidebar Styling */
.appointment-dashboard-sidebar {
    width: var(--sidebar-width);
    min-width: var(--sidebar-width);
    background: var(--primary-color);
    padding: 2rem 1.5rem;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 100vh;
    overflow-y: auto;
    z-index: 1000;
    --logo-size: 0.7;
  position: relative;
}
tr{
  text-align: left;
}
.appointment-dashboard-sidebar .logo-box h1{
  margin-bottom: 0;
}
.total-count{
  color:#ced4da !important;
  font-size: 0.8rem !important;
  margin-top: 5px !important;
}

.appointment-dashboard-logo {
    text-align: center;
    background: rgba(255, 255, 255);
    border-radius: var(--rounded-sm);
    backdrop-filter: blur(10px);
    white-space: nowrap;
}

/* Navigation Items */
.appointment-dashboard-nav-items {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.appointment-dashboard-nav-item {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 1rem;
    border-radius: var(--rounded-sm);
    cursor: pointer;
    transition: all var(--transition-speed);
    text-decoration: none !important;
    white-space: nowrap;
    border: none;
    background-color: transparent;
    outline: none;
    gap: 10px;
    color: white !important;
}

/* Main Content Area */
.admin-dashboard-content {
    flex: 1;
    margin-left: var(--sidebar-width);
    padding: 2rem;
    min-width: 0;
    overflow-x: auto; /* Allows horizontal scrolling */
}

/* Table Container */
.appointment-dashboard-appointments-list {
    background: white;
    border-radius: var(--rounded-lg);
    box-shadow: var(--shadow-lg);
    overflow-x: auto; /* Allows table to expand horizontally */
    width: 100%;
}

/* Table Header */
.appointment-dashboard-appointment-header {
    display: grid;
    grid-template-columns: 80px 180px 180px 200px 220px 150px 100px 100px; /* Fixed widths */
    padding: 1rem;
    background: var(--primary-color);
    color: white;
    font-weight: 600;
    position: sticky;
    top: 0;
    z-index: 10;
    min-width: 1110px; /* Sum of column widths + gaps */
}

/* Table Rows */
.appointment-dashboard-appointment-item {
    display: grid;
    grid-template-columns: 80px 180px 180px 200px 220px 150px 100px 100px; /* Consistent with header */
    padding: 1rem;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    transition: background-color var(--transition-speed);
    min-width: 1110px; /* Sum of column widths + gaps */
}

.appointment-dashboard-appointment-item > div {
    padding: 0 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.appointment-dashboard-edit-button{
    background: transparent;
    border: none;
    outline: none;
}

/* Search Input Customization */
.appointment-dashboard-content .input-group.border {
  border: 1px solid #ced4da;
}

/* Date Picker Customization */
.react-datepicker-wrapper {
  width: 100%;
}

.appointment-dashboard-editable-date-time {
  cursor: pointer;
}

/* Appointment Details Styling */
.appointment-dashboard-appointment-details {
  transition: max-height 0.3s ease;
}

/* Icon Styling */
.appointment-dashboard-user-avatar {
  font-size: 1.5rem;
}

/* Ensure buttons are properly spaced */
.appointment-dashboard-action-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Additional Hover Effects */
.appointment-dashboard-appointment-item:hover {
  background-color: #f8f9fa;
}

/* Status Filter Buttons */
.status-filter-button {
  text-decoration: none;
  color: #6c757d; /* Bootstrap's secondary color */
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
}

.status-filter-button.active-status {
  text-decoration: underline;
  color: #000; /* Black color for active status */
}

.status-filter-button.inactive-status {
  opacity: 0.7; /* Slightly faded for inactive statuses */
}

/* Border Bottom for Appointment Rows */
.row.border-bottom {
  border-bottom: 1px solid #dee2e6;
}

/* Adjustments for Calendar Icons */
.text-muted {
  color: #6c757d !important;
}

/* Search Button Alignment */
.input-group .btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .status-filter-button {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }
}
/* Doctor Dashboard Header */

  
  /* Filter Container */
  .doctor-filter {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: end;
    margin-bottom: 20px;
  }
  
  .doctor-filter label {
    font-weight: 600;
    color: var(--text-primary);
  }
  
  .doctor-filter-select {
    padding: 0.5rem 1rem;
    border-radius: var(--rounded-sm);
    border: 1px solid #e0e0e0;
    background: white;
    font-size: 0.9rem;
    transition: all var(--transition-speed);
  }
  
  .doctor-filter-select:focus {
    border-color: var(--primary-color);
    outline: none;
    box-shadow: 0 0 0 2px rgba(67, 97, 238, 0.2);
  }

.doctor-dashboard-header {
    display: grid;
    grid-template-columns: 80px 80px 150px 150px 200px 120px 120px 150px 120px 100px 80px;
    padding: 1rem;
    background: var(--primary-color);
    color: white;
    font-weight: 600;
    position: sticky;
    top: 0;
    z-index: 10;
    min-width: 1360px;
}

/* Doctor Dashboard Rows */
.doctor-dashboard-item {
    display: grid;
    grid-template-columns: 80px 80px 150px 150px 200px 120px 120px 150px 120px 100px 80px;
    padding: 1rem;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    transition: background-color var(--transition-speed);
    min-width: 1360px;
}

/* Profile Photo Styling */
.doctor-profile-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}
/* Patient Dashboard Header */
/* Search Container */
.patient-search {
    position: relative;
    width: 100%;
    text-align: right;
    margin-bottom: 20px;
  }
  
  .patient-search-input {
    width: 30%;
    padding: 0.5rem 1rem;
    border-radius: var(--rounded-sm);
    border: 1px solid #e0e0e0;
    background: white;
    font-size: 0.9rem;
    transition: all var(--transition-speed);
  }
  
  .patient-search-input:focus {
    border-color: var(--primary-color);
    outline: none;
    box-shadow: 0 0 0 2px rgba(67, 97, 238, 0.2);
  }
  
.patient-dashboard-header {
    display: grid;
    grid-template-columns: 80px 150px 200px 120px 120px 150px 100px 80px;
    padding: 1rem;
    background: var(--primary-color);
    color: white;
    font-weight: 600;
    position: sticky;
    top: 0;
    z-index: 10;
    min-width: 970px;
}

/* Patient Dashboard Rows */
.patient-dashboard-item {
    display: grid;
    grid-template-columns: 80px 150px 200px 120px 120px 150px 100px 80px;
    padding: 1rem;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    transition: background-color var(--transition-speed);
    min-width: 970px;
}

/* Status and Doctor Select Dropdowns */
.appointment-dashboard-status-select,
.appointment-dashboard-doctor-select {
    width: 100%;
    max-width: 200px;
    padding: 0.5rem;
    border-radius: var(--rounded-sm);
    border: 1px solid #e0e0e0;
    background: white;
    font-size: 0.9rem;
    transition: all var(--transition-speed);
}

/* User Info Section */
.appointment-dashboard-user-info {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    min-width: 0;
}

.appointment-dashboard-user-info span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.appointment-dashboard-user-avatar {
    min-width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background: linear-gradient(45deg, var(--primary-light), var(--primary-color));
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    flex-shrink: 0;
}

/* Expanded Details Section */
.appointment-dashboard-appointment-details {
    padding: 1.5rem;
    background: rgba(67, 97, 238, 0.03);
    margin: 0 1rem 1rem 1rem;
    border-radius: var(--rounded-md);
    border: 1px solid rgba(67, 97, 238, 0.1);
}

.appointment-dashboard-details-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
}

.appointment-dashboard-detail-section {
    background: white;
    padding: 1.5rem;
    border-radius: var(--rounded-sm);
    box-shadow: var(--shadow-sm);
    min-width: 300px;
}

/* Date Picker Container */
.appointment-dashboard-editable-date-time {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border-radius: var(--rounded-sm);
    background: rgba(67, 97, 238, 0.05);
    cursor: pointer;
    transition: all var(--transition-speed);
    white-space: nowrap;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Responsive Design */
@media (max-width: 1400px) {
    .appointment-dashboard-details-grid {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
}

@media (max-width: 768px) {
    .appointment-dashboard-sidebar {
        position: relative;
        width: 100%;
        height: auto;
        min-height: auto;
    }

    .admin-dashboard-content {
        margin-left: 0;
    }

    .appointment-dashboard-details-grid {
        grid-template-columns: 1fr;
    }
}



/* Action Button Styling */
.appointment-dashboard-action-button {
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    transition: transform var(--transition-speed);
    display: flex;
    align-items: center;
    justify-content: center;
}

.appointment-dashboard-action-button:hover {
    transform: scale(1.1);
}

.appointment-dashboard-action-button:focus {
    outline: none;
}

.appointment-dashboard-action-button svg {
    width: 1em;
    height: 1em;
    color: var(--primary-color);
}


/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

/* Styling for the admin login page container */
.admin-login-page {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

/* Container for the login form */
.admin-login-container {
  background: #fff;
  padding: 40px 30px;
  border-radius: 10px;
  box-shadow: 0 15px 25px rgba(0,0,0,0.1);
  width: 400px;
  max-width: 90%;
}

/* Form styling */
.admin-login-form {
  display: flex;
  flex-direction: column;
}

/* Form title */
.admin-login-form h2 {
  margin-bottom: 30px;
  color: #333;
  text-align: center;
  font-weight: 500;
}

/* Input fields styling */
.admin-login-form input[type="text"],
.admin-login-form input[type="password"] {
  width: 100%;
  padding: 15px 20px;
  margin-bottom: 20px;
  border: 2px solid #ddd;
  border-radius: 30px;
  font-size: 16px;
  transition: border-color 0.3s;
  box-sizing: border-box;
}

.admin-login-form input:focus {
  border-color: #9b59b6;
  outline: none;
}

/* Placeholder styling */
.admin-login-form input::placeholder {
  color: #aaa;
}

/* Submit button styling */
.admin-login-form button {
  width: 100%;
  padding: 15px 20px;
  background: #9b59b6;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s;
}

.admin-login-form button:hover {
  background: #8e44ad;
}

/* Error message styling */
.admin-login-form .error-message {
  margin-top: 15px;
  color: #e74c3c;
  text-align: center;
  font-size: 14px;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .admin-login-container {
    padding: 30px 20px;
  }

  .admin-login-form h2 {
    font-size: 24px;
  }
}

/* AdminDashboard.css */

/* Sidebar container */
.appointment-dashboard-sidebar {
  width: 250px;
  min-width: 250px;
  transition: all 0.3s;
}

/* Hide sidebar on small screens */
@media (max-width: 992px) {
  
  .appointment-dashboard-sidebar {
    position: fixed;
    top: 0;
    left: -250px; /* hide by default */
    height: 100%;
    z-index: 999;
  }
  /* When open, shift it into view */
  .appointment-dashboard-sidebar.open {
    left: 0;
  }
  .appointment-dashboard-sidebar .logo-box {
    display: none;
}
.admin-navbar-close{
 
  display: block;
}

}
/* The nav items */
.appointment-dashboard-nav-items {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.appointment-dashboard-nav-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  color: #333;
  text-decoration: none;
  transition: background-color 0.2s;
}

.appointment-dashboard-nav-item:hover {
  background-color: #f1f1f1;
}

/* Hamburger button (shown only on smaller screens) */
.navbar-hamburger {
  display: none;
  background: none;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
  margin-left: 1rem;
}

@media (max-width: 992px) {
  .navbar-hamburger {
    display: block;
  }
}

/* Main content container */
.admin-dashboard-container {
  display: flex;
  flex-direction: row;
}

/* Content area */
.admin-dashboard-content {
  flex: 1;
  padding: 1rem;
  transition: margin-left 0.3s ease-in-out;
  margin-left: 250px; /* offset for the sidebar */
}

@media (max-width: 992px) {
  .admin-dashboard-content {
    margin-left: 0; /* no offset if sidebar is hidden */
  }
}

/* Table responsiveness overrides */
table.table {
  width: 100%;
  overflow-x: auto;
}

/* Adjust text sizes and spacing on smaller screens */
@media (max-width: 576px) {
  .admin-dashboard-content {
    padding: 0.5rem;
  }



  .appointment-dashboard-nav-item {
    justify-content: center;
  }

  /* Filter row stacking */
  .filters-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .filters-row .col {
    margin-bottom: 0.5rem;
  }

  /* Buttons smaller */
  .status-filter-button {
    padding: 6px;
  }

  /* Table cells padding */
  .table td {
    padding: 0.5rem;
  }
}


/* AdminHeader Styles */
.admin-header {
  display: none;
 
}
.admin-navbar-close{
  position: absolute;
  right: 1%;
  top: 1%;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
  border: none;
  outline: none;
  display: none;
}


/* Right section: Hamburger button */


/* Mobile header for smaller screens */
@media (max-width: 768px) {
  /* Adjust the header for smaller screens */
  .admin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0 10px;
    width: 100%;
  }
  .navbar-hamburger-btn {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
 


  /* Mobile header */
  .mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
  }


  .menu-toggle {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
}
