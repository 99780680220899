.appointment-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .appointment-modal {
    width: 60%;
   
    height: 550px; /* Give a fixed height to the modal */
    background-size: cover;
    background-position: bottom;
    background-image: url('https://www.medbridgeaccess.com/static/banner/call-appointment.webp'); /* Path to your image */
    border-radius: 8px;
    display: flex;
    justify-content: flex-end; /* Push the form to the right */
    position: relative;
  
  }
  
  .appointment-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
   
  }
  
  .appointment-modal-content {
    display: flex;
    flex-direction: column;
    width: 50%; /* Form container takes 50% of the modal width */
    padding: 20px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
  }
  .appointment-form-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  } 
  .appointment-form-container h2 {
   font-size: 1.2rem;
    margin-bottom: 5px;
    text-align: left;
    width: 100%;
    color:  #e63946;
  }
  
  .appointment-form-container p {
    margin: 5px 0 15px 0;
    text-align: left;
    font-size: 0.8rem;
    width: 100%;
  }
  .appointment-form-container form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .appointment-form-container input,
  .appointment-form-container textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: none;
    border-radius: 25px;
     
    font-size: 1rem;
  }
  
  .appointment-form-container textarea {
    height: 120px;
    resize: none;
  }
  
  .appointment-form-container button {
    background-color: #e63946;
    color: white;
    padding: 12px 15px;
    border: none;
    border-radius: 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
  }
  /* AppointmentModal.css */

.appointment-thankyou-container {
  text-align: center;
  padding: 20px;
}

.appointment-thankyou-container h2 {
  color: #4caf50; /* A pleasant green color */
  margin-bottom: 15px;
}

.appointment-thankyou-container p {
  color: #555;
  margin-bottom: 20px;
  line-height: 1.6;
}



.appointment-thankyou-container button:hover {
  background-color: #45a049;
}
.pricing-details {
  width: 50%;
}

.pricing-details table {
  width: 100%;
  border-collapse: collapse;
}

.pricing-details td {
  padding: 10px;
  text-align: left;
}

/* Remove any border or background if present */
.pricing-details tr:nth-child(even) {
  background-color: transparent; /* Changed from #f9f9f9 to transparent */
}



/* New styles for decreased font size */
.original-price,
.discount {
  font-size: 0.9em; /* Decrease font size */
  color: #555; /* Optional: a softer color for original price and discount */
}

.original-price-value,
.discount-value {
  font-size: 0.9em; /* Decrease font size */
  color: #555; /* Optional: match the color of the labels */
  text-align: right !important;
}

/* New styles for increased font size of final price */
.final-price {
  font-size: 1.2em; /* Increase font size */
  display: flex;
  width: 100%;
  justify-content: right;
}


  .appointment-form-container button:hover {
    background-color: #d62828;
  }
  @media (max-width:768px) {
    .appointment-modal
    {
        width: 90%;
        background-position: -185px 20px;;
    }
    .appointment-modal-content{
        width: 85%;
    }
  }