/* Page Layout */
.page-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    width: 100%;
  }
  
  .left-side {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    height:100%;
  }
  
  .right-side {
    position: absolute;
    background: #fde9e3; /* Light pinkish background */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    right: 2%;
    bottom: 2%;
    width: 40%;
  }
   

  .paper-content {
    height: 22vh;
    background: linear-gradient(transparent, transparent 27px, black 27px);
    background-size: 28px 28px;
    position: relative; /* Ensures proper stacking context */
    margin-bottom: 30px;
  }
  
  .paper-content textarea {
    width: 100%;
    height: 100%;
    padding: 5px 10px 0 10px; /* Adds top padding for alignment */
    border: 0;
    outline: 0;
    background: transparent;
    color: black;
    font-family: 'Handlee', cursive;
    font-weight: bold;
    font-size: 17px;
    line-height: 28px; /* Matches the background line spacing */
    box-sizing: border-box;
    z-index: 1;
    resize: none;
    overflow: auto; /* Ensures scrollbar appears if content overflows */
    margin: 0;
  }
  
  /* Left Side (Image and Promo Text) */
  .image-section {
    width: 100%;
    height: 62vh;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  
  .image-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .promo-text-section {
    position: relative;
    background: #fff;
    padding: 2rem 0;
    color: #333;
    width: 100%;
    height:35%;
    display:flex;
    align-items:center;
  }
  .promo-text-section-box{
    width: 30%;
    text-align: left;
    margin-left: 60px;
  }
  
  .promo-text-section h3 {
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-bottom: 0rem;
    letter-spacing: 0.05em;
    color: #333;
  }
  
  .promo-text-section h1 {
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    font-weight: bold;
    color: #000;
    margin-top: 7px;
  }
  
  .promo-text-section p {
    font-size: 0.9rem;
    line-height: 1.5;
    color: #555;
  }
  
  /* Right Side (Forms and Content) */
  .right-side .form-section {
  
    width: 100%;
    background:transparent; /* White background for the form container */
  }
.appointemnt-form-container,.appointemnt-form-container form{
  width: 100%;
}
  .appointment-quote-page-header {
    display: none; /* Hide original empty header if not needed */
  }
  
  .page-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .page-header h1 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .page-header p {
    font-size: 1rem;
    color: #555;
  }
  .appointment-btns{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  
  .btn,
  .link-button {
    appearance: none;
    border: none;
    background: #000;
    color: #fff;
    padding: 0.8rem 1.2rem;
    border-radius: 0px;
    cursor: pointer;
   
    font-size: 0.8rem;
  }
  
  .btn:hover,
  .link-button:hover {
    background: #333;
  }
  
  .link-button {
    background: none;
    color: #000;
    text-decoration: underline;
    padding: 0;
    border-radius: 0;
    font-weight: normal;
    text-transform: none;
    letter-spacing: normal;
    margin-left: 0.8rem;
  }
  
  .link-button:hover {
    background: none;
    color: #333;
  }
  
  /* Appointment Form Container */
  .appointemnt-form-container,
  .form-container {
    text-align: center;
  }
  
  .appointemnt-form-container h2,
  .form-container h2 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  
  .appointemnt-form-container p,
  .form-container p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 1.5rem;
  }
  
  /* Pricing Details Table */
  .pricing-details {
    margin-bottom: 1.5rem;
  }
  
  .pricing-details table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.95rem;
  }
  
  .pricing-details td {
    padding: 0.4rem 0;
    color: #555;
  }
  
  .pricing-details .original-price {
    color: #333;
    font-weight: bold;
  }
  
  .pricing-details .discount {
    color: #333;
  }
  
  .pricing-details .final-price {
    color: #000;
    font-weight: bold;
  }
  
  /* Success Containers */
  .success-container {
    text-align: center;
    padding: 2rem;
  }
  
  .success-container h2 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  
  .success-container p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 1.5rem;
  }
  
  /* Toggle Link */
  .toggle-link {
    margin-top: 0;
    width: 55%;
   
  }
  
  .toggle-link p {
    font-size: 0.95rem;
    color: #555;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: end;
  }
  .toggle-link p button{
    padding: 0.8rem 1.2rem;
    border-radius: 0px;
    cursor: pointer;
   border: 1px solid black;
   background-color: transparent;
    font-size: 0.8rem;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 992px) {
    .page-container {
      flex-direction: column;
    }
  
    .promo-text-section {
      margin-left: 0;
      width: 100%;
      height:45%;
      padding:20px 0;
    }
  
    .image-section {
      position: relative;
      width: 100%;
      height: 31vh;
    }
  
    .right-side {
      padding: 2rem 0;
      position: relative;
      width: 100%;
      right: 0;
      bottom: 0;
    }
    .promo-text-section-box {
      width: 100%;
      text-align: center;
      margin-left:0;
  }
  .right-side .form-section {
    width: 90%;
}
  }
  