.doctor-profile {
    
    padding:0 ;
    display: flex;
    align-items: self-start;
    width: 100%;
    flex-direction: column;
  }
  .doctor-profile .banner-image{
    width: 100%;
    height: 40vh;
  }
  .doctor-profile .banner-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .doctor-profile-box{
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;

  }
  .doctor-profile-card-box{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
  }
  .doctor-profile-card-box h2,.doctor-profile-card-box p{
width: 100%;
text-align: left;
padding-left: 30px;
margin: 5px 0;
  }

  .doctor-profile-card {
    background: white;
    padding: 20px;
    border-radius: 20px;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
  .doctor-profile-card button{
    width: 60%;
    color: white;
    background-color: #d77574;
    border: none;
    outline: none;
    padding: 10px 0;
    margin-top: 20px;
  }
.doctor-profile-box form{
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: center;
justify-content: space-between;
}
.doctor-form-group-box{
  display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: center;
justify-content: space-between;

}
  .doctor-profile-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 15px;
  }

  .doctor-profile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .doctor-profile-info {
    text-align: center;
  }

  .doctor-profile-info h2 {
    color: #333;
    margin-bottom: 10px;
    font-size: 1.5rem;
  }

  .doctor-profile-info p {
    color: #666;
    margin-bottom: 8px;
    font-size: 14px;
  }

  .doctor-profile-form {
    background: white;
    padding: 30px;
    border-radius: 10px;
    width: 80%;
    text-align: left;
  }

  .doctor-profile-form h2 {
    color: #333;
    margin-bottom: 20px;
    font-size: 1.5rem;
  }

  .doctor-form-group {
    margin-bottom: 20px;
    width: 40%;
  }

  .doctor-form-group label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-weight: 500;
  }

  .doctor-form-group input {
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    border-radius: 0;
    margin: 0;
  }

  .doctor-form-group input:focus {
    outline: none;
    border-color: #4754F8;
    box-shadow: 0 0 0 2px rgba(71, 84, 248, 0.1);
  }

  .doctor-update-btn {
    background: #d77574;
    color: white;
    border: none;
    padding: 20px 20px;
    width: 25%;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s;
  }

  .doctor-update-btn:hover {
    background: #3442E5;
  }

  @media (max-width: 768px) {
   
    .doctor-profile-box {
      flex-direction: column;
     }
     .doctor-profile-card {
      width: 100%;
  }
  .doctor-profile-form {
  
    width: 90%;
}
.doctor-form-group {
  width: 100%;
}
.doctor-update-btn{
  width: 100%;
}
.doctor-profile .banner-image {
  height: 20vh;
}
  }