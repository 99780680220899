.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-content-login {
  background: #fff;
  width: 70%;
  display: flex;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.modal-left {
  padding: 20px 40px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.modal-left .logo-login {
 
    --logo-size: 0.6; /* 40% of the original size */
    width: 100%;
    text-align: left;

  }
  

.modal-left h2 {
  margin: 0;
  font-size: 35px;
  color: #333;
  text-align: left;
}

.modal-left .subheading {
  margin: 10px 0 30px;
  font-size: 14px;
  color: #666;
  text-align: left;
}
.login-form-modal{
  width: 65%;
  margin: 60px 0;
}
.login-form-modal form{
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.modal-left input[type="email"],
.modal-left input[type="password"] {
  width: 100%;
  padding: 15px 10px;
  margin: 10px 0;
  border: none;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}

.modal-left .forgot-password {
  display: block;
  margin-bottom: 20px;
  text-align: right;
  color: #a7185d;
  text-decoration: none;
  font-size: 13px;
  width: 100%;
}

.modal-left button {
  width: 100%;
  padding: 12px;
  background-color: #a7185d;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.modal-left button:disabled {
  background-color: #a9d8ff;
}

.modal-left .error-message {
  color: red;
  margin-top: 20px;
}



.modal-right .quote-container {
  text-align: left;
  width: 90%;
}

.modal-right .quote-text {
  font-size: 28px;
  line-height: 1.5;
  color: white;
}

.modal-right .quote-author {
  margin-top: 20px;
  font-size: 22px;
  font-style: italic;
  letter-spacing: 0.04rem;
}

.close-button-login {
  position: absolute;
  top: 0px;
  right: 2px;
  color: #fff;
  border: none;
  border-radius: 50%;
  font-size: 64px;
  
  cursor: pointer;
  background-color: transparent;
}
/* Styles for devices below 768px */
@media (max-width: 768px) {
  .logo-box {
    --logo-size: 0.6;
}
.modal-left h2 {
 
  font-size: 25px;
 
}
  .modal-content-login {
    flex-direction: column;
    width: 90%;
  }

  .modal-left {
    width: 100%;
    padding: 20px;
  }

  .login-form-modal {
    width: 100%;
    margin: 40px 0;
  }

  .modal-right {
    width: 100% !important;
    height: 200px;
    background-size: cover;
    order: -1; /* Move the right section to the top */
  }

  .modal-right .quote-container {
    display: none; /* Hide the quote on smaller screens */
  }
  .modal-left .subheading {
    margin: 10px 0 20px;
    font-size: 12px;
   
}
.modal-left input[type="email"], .modal-left input[type="password"] {
 
  padding: 10px 10px;
 
  font-size: 13px;
}
.modal-left .forgot-password {
 
  font-size: 12px;

}
.modal-left button{
  padding: 10px;
  font-size: 15px;
}
}