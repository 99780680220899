.ufm-content {
  flex: 1;
  padding: 20px;
  position: relative;
}

/* Upload Section */
.ufm-upload-section {
  background: #ffffff37;
  border: 0.5px dashed #ccc;
  border-radius: 8px;
  padding: 40px 20px;
  text-align: center;
  margin-bottom: 30px;
  position: relative;
}

.ufm-upload-dropzone {
  position: relative;
}

.ufm-upload-label {
  cursor: pointer;
  display: inline-block;
}

.ufm-upload-icon img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.ufm-upload-title {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 5px;
}

.ufm-max-size {
  font-size: 14px;
  color: #777;
}

.ufm-upload-message {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
}
.ufm-upload-message.info {
  background: #eaf4ff;
  color: #3178c6;
}
.ufm-upload-message.success {
  background: #e9f7ec;
  color: #2e7d32;
}
.ufm-upload-message.error {
  background: #fdecea;
  color: #c62828;
}

/* Section Titles */
.ufm-section-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
}

/* Diagnoses Section */
.ufm-diagnoses-section {
  background: #ffffff37;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
}

/* Desktop Table View */
.ufm-diagnoses-table-container {
  width: 100%;
  overflow-x: auto;
}

.ufm-diagnoses-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;
}

.ufm-diagnoses-table tr {
  border-bottom: 0.5px solid rgb(243, 238, 238);
}

.ufm-diagnoses-table th,
.ufm-diagnoses-table td {
  padding: 10px;
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
}

.ufm-diagnoses-table th {
  font-weight: 600;
}

.ufm-link {
  color: #1a73e8;
  text-decoration: none;
  font-weight: 500;
}
.ufm-link:hover {
  text-decoration: underline;
}

/* Doctor Cell in Diagnoses */
.ufm-doctor-cell {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ufm-doctor-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.ufm-doctor-info {
  display: flex;
  flex-direction: column;
}

.ufm-doctor-name {
  font-weight: 600;
  font-size: 14px;
  margin: 0;
}

.ufm-doctor-specialization {
  font-size: 12px;
  color: #777;
}

.ufm-description-link {
  color: #1a73e8;
  cursor: pointer;
  text-decoration: underline;
}

/* Mobile Card View */
.ufm-diagnosis-cards-container {
  display: none;
  margin-top: 20px;
}

.ufm-diagnosis-card {
  background: #ffffff37;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
}

.ufm-diagnosis-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ufm-arrow-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: transform 0.2s ease;
  color: #333;
}

.ufm-arrow-button.expanded {
  transform: rotate(180deg);
}

.ufm-diagnosis-card-details {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.4;
}

.ufm-diagnosis-card-row {
  margin-bottom: 8px;
}

.ufm-diagnosis-card-row strong {
  font-weight: 600;
}

/* Hide table on small screens and show cards instead */
@media (max-width: 768px) {
  .ufm-diagnoses-table-container {
    display: none;
  }
  .ufm-diagnosis-cards-container {
    display: block;
  }
}

/* My Reports Section */
.ufm-my-reports-section {
  background: #ffffff37;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
}

.ufm-my-reports-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Options (3-dot) Menu in My Reports */
.ufm-my-reports-options {
  position: relative;
}

.ufm-options-button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size: 18px;
  color: #333;
  border-radius: 4px;
  outline: none;
}

.ufm-options-button:hover {
  background: rgba(0,0,0,0.1);
}

.ufm-options-menu {
  position: absolute;
  top: 30px;
  right: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 10;
  width: 120px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.ufm-options-menu-item {
  padding: 8px 10px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.2s ease;
}
.ufm-options-menu-item:hover {
  background: #f2f2f2;
}

.ufm-my-reports-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px,1fr));
  gap: 20px;
}

.ufm-report-item {
  border-radius: 20px;
  padding: 15px;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 10px;
  background-color: #ffffff45;
  text-decoration: none;
  color: #000;
  transition: background 0.2s ease;
}

.ufm-report-item:hover {
  background-color: #ffffff60;
}

.ufm-report-icon {
  width: 80px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #fff;
}

/* Different colored icons */
.ufm-report-icon-blue {
  background-color: #3f51b5;
}
.ufm-report-icon-green {
  background-color: #4caf50;
}
.ufm-report-icon-orange {
  background-color: #ff9800;
}

.ufm-report-name {
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
}

.ufm-report-date {
  font-size: 13px;
  color: #777;
  margin-top: 0;
  margin-bottom: 0;
}

.ufm-report-details {
  text-align: left;
}

.ufm-report-delete-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
}
.ufm-report-delete-icon img {
  width: 16px;
  height: 16px;
}

/* Delete Confirmation Modal */
.ufm-delete-modal-overlay,
.ufm-description-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.ufm-delete-modal-content,
.ufm-description-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
}

.ufm-delete-modal-content h4,
.ufm-description-modal-content h4 {
  margin-top: 0;
  margin-bottom: 10px;
}

.ufm-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.ufm-cancel-btn,
.ufm-confirm-delete-btn {
  background: #e0e0e0;
  border: none;
  border-radius: 4px;
  padding: 6px 10px;
  cursor: pointer;
  font-size: 14px;
}

.ufm-confirm-delete-btn {
  background: #c62828;
  color: #fff;
}
