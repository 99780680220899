.dashboard-doctor {
    width: 93%;
   overflow-y: auto;
   background-color: #efeced;
   border-radius: 15px;
   color: #59657F !important;
  }
  .dashboard-banner img{
width: 100%;
  }
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .add-appointment-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .dashboard-body {
    display: flex;
    justify-content: space-between;
width: 100%;
  }
  
  .banner-image img{
    width: 100%;
    height: 40vh;
    object-fit: cover;
  }
  
/* calendar */
  
  .calendar-container {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: start ;
    margin-top: -200px;
  
   
  }
  .calendar-section{
    width: 100%;
    padding-top: 0;
  }
  
  .doctorSidebar2__profile-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    position: relative;
    
    margin-top: 20px;

  }
  .doctorSidebar2__profile-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;
  }
  
  .doctorSidebar2__avatar {
   
    border-radius: 50%;
    overflow: hidden;
    width: 110px;
    height: 110px;
    margin-bottom: 5px;
   
   
  }

  .doctorSidebar2__avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .doctorSidebar2__doctor-info{
    text-align: center;
    
  }

  .doctorSidebar2__doctor-info h2 {
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
  }

  .doctorSidebar2__doctor-info p {
    color: #989da9;
    margin: 0;
    font-size: 0.8em;
    font-weight: 400;
  }
  .doctorSidebar2__stat-image{
    width: 55px;
    display: flex;
    align-items: start;
  }
  .doctorSidebar2__stat-image img{
    width: 90%;
  }
  .doctorSidebar2__stat-info{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .calendar-profile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    background-color: white;
    padding: 20px;
    border-radius: 25px;
  }
  .calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .month-year-display {
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
  }
  .calendar-box{
    width: 100%;
    padding: 20px;
    border-radius: 20px;
  }
  
  .calendar-nav-button {
   background-color: white;
    font-size: 0.9rem;
    border-radius: 25%;
    width: 30px;
    height: 30px;
    border: 0.5px solid rgb(225, 224, 224);
    display: flex;
    align-items: center;
    justify-content: center
  }
  
  .month-dropdown {
    margin-bottom: 10px;
  }
  
  .month-dropdown select {
    width: 100%;
    padding: 5px;
    font-size: 16px;
  }
  
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 2px;
  }
  
  .calendar-day-of-week {
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    color: #666;
    font-size: 0.9rem;
  }
  
  .calendar-date {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 25%;
    cursor: pointer;
  }
  .doc-profile-button{
    margin-top: 10px;
    background-color: #f68092;
    color: white;
    padding: 10px 30px;
    border-radius: 20px;
    border: none;
    outline:none;
    font-weight: 100;
  }
  
  .calendar-date:not(.empty):hover {
    background-color: #f0f0f0;
  }
  
  .calendar-number {
    font-size: 0.8rem;
  }
  
  .calendar-date.today {
    background-color: #e0e0e0;
    font-weight: bold;
  }
  
  .calendar-date.selected {
    background-color: #F68092;
    color: white;
  }
  
  .calendar-date.empty {
    pointer-events: none;
  }
  .calendar-arrows{
    gap: 5px;
    width: 22%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
 

  .appointment-updates{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 75%;
  }


  /* DoctorStats.css */
/* DoctorStats.css */
.statistics-card {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  padding: 20px;
  width: 300px;
}

.statistics-card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.statistics-card h2 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.time-select {
  border: none;
  background: none;
  color: #666;
  font-size: 14px;
}

.stats-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.circular-progress {
  width: 100px;
  height: 100px;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  stroke: #4CAF50;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.percentage {
  fill: #4CAF50;
  font-size: 0.5em;
  text-anchor: middle;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-value {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

.stat-label {
  font-size: 12px;
  color: #666;
  margin-bottom: 5px;
}

.stat-percentage {
  font-size: 12px;
  font-weight: bold;
}

.total .stat-value, .total .stat-percentage { color: #7E90C2; }
.pending .stat-value, .pending .stat-percentage { color: #7E90C2; }
.canceled .stat-value, .canceled .stat-percentage { color: #7E90C2; }

 
  .appointment-requests .request {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .accept-btn {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
  }





   /* AppointmentUpdates.css */

/* Container for Appointment Updates */
.appointment-updates {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 0 20px; /* Optional: Add padding if needed */
}
.appointment-updates__content{
  width: 95%;
}
/* Search Bar */
.appointment-updates__search-bar {
  position: relative;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}

.welcome-content{
  text-align: left;
}
.welcome-content h1{
  margin: 0;
}
.welcome-content .subtitle{
  font-size: 1rem;
  text-align: left;
  margin: 5px 0;
}
.appointment-updates__search-bar .search-bar {
  background: #fff6;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.appointment-updates__search-bar .search-icon {
  padding: 15px;
  background: #fff6;
  border-radius: 10px;
}

.appointment-updates__search-bar .search-input {
  width: 300px;
  padding: 15px;
  background: transparent;
  font-size: 14px;
  border: none;
  outline: none;
}

/* Date Text */
.appointment-updates__date-text {
  font-size: 0.9rem;
  color: #8B94A6;
  font-weight: 600;
  margin-bottom: 10px;
}

/* Welcome Card */
.appointment-updates__welcome-card {
  
  object-fit: cover;
  
  margin: 20px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color:#ecbd7e;
 

overflow: hidden;
}
.welcome-card-overlay{
  width: 100%;
  height: 100%;
  color: #CDD1D9;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.appointment-updates__welcome-card .welcome-content {
  text-align: left;
  width: 65%;
  padding: 20px 30px;
}

.appointment-updates__welcome-card .title {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: black;
}

.appointment-updates__welcome-card .subtitle {
  font-size: 0.9rem;
  margin: 10px 0;
  text-align: left;
  width: 80%;
  font-weight: 100;
  color: black;
}

.appointment-updates__welcome-card .button {
  background: black;
  border: none;
  padding: 13px 14px;
  border-radius: 25px;
  font-weight: 400;
  cursor: pointer;
  color: white;
  margin-top: 20px;
  font-size: 0.8rem;
}

.appointment-updates__welcome-card .banner-image {
  width: 35%;
  height: 30vh;
  
}

.appointment-updates__welcome-card .banner-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.appointment-updates__welcome-card .illustration {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  position: absolute;
  right: 15%;
  bottom: 25%;
  overflow: visible;

}

/* Stats Cards */
.appointment-updates__cards-box {
  margin: 10px 0;
}

.appointment-updates__cards-heading {
  font-size: 20px;
  color: #1a1a1a;
  font-weight: 600;
  text-align: left;
}

.appointment-updates__cards-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

@media (min-width: 768px) {
  .appointment-updates__cards-container {
    grid-template-columns: repeat(3, 1fr);
  }
  
}

.appointment-updates__card {
  background: #F5F7FF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}



.appointment-updates__card-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
}
.appointment-updates__card-content{
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  color: #fff;
  position: relative;
}
.appointment-updates__card-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.112); /* Dark overlay */
  /* Alternatively, use a brightness filter if you prefer */
  /* filter: brightness(70%); */
  pointer-events: none; /* Allows clicks to pass through */
}
.appointment-updates__card-left{
  display: flex;
  flex-direction: column;
  align-items: start;
  position: absolute;
  bottom: 0;             /* Position the top edge at 50% of the container's height */
  left: 0;                /* Align the element to the left edge */
  margin-left: 10px;
  margin-bottom: 10px;
}
.appointment-updates__card-right{
  width: 100%;
  height: 100%;
}

.appointment-updates__card-right img{
  width: 100%;
  object-fit: cover;
  height: 100%;

}
.appointment-updates__card-quote{
  font-size: 1.1rem;
  text-align: start;
  margin-top: 5px;
}

.appointment-updates__card-title {
  font-size: 1rem;
  color: #fff !important;
  margin-bottom: 15px;
  font-weight: 400 !important;
}
.appointment-updates__card-value{
  font-size: 2.7rem;
  margin: 0;
  font-weight: 600;
}

.appointment-updates__avatar-group {
  display: flex;
  margin-left: 8px;
  color: white;
}

.appointment-updates__avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  margin-left: -8px;
  border: 2px solid #F5F7FF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.appointment-updates__avatar-text {
  color: #4755D9;
  font-weight: bold;
  font-size: 12px;
}

.appointment-updates__card-info {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 0.8rem;
}












  
  @media (max-width: 768px) {

  
.dashboard-doctor{
  width: 100%;
}

.calendar-container{
  flex-direction: column-reverse;
  align-items: center;
  padding: 20px 0;
}
.calendar-profile{
  width: 85%;
}
.appointment-updates{
  width: 100%;
}
.calendar-title {
  font-size: 19px;
  
}
.appointment-updates__welcome-card .banner-image {
  width: 100%;
  height: auto;
}
.appointment-updates__welcome-card{
  flex-direction: column-reverse;
}
.appointment-updates__search-bar {
  
  justify-content: start;
  flex-direction: column-reverse;
  align-items: self-start;
  gap: 20px;
  padding: 0 10px;
}
.appointment-updates__search-bar .search-bar {

  width: 100%;
}
.banner-image{
  border-radius: 0;

}
.banner-image img {
 
  height: 28vh;
}

  }

 