/* src/CSS/DoctorInfoBox.css */

.doctor-info-box {
    position: absolute;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* Ensure it's above other elements */
    width: 300px; /* Adjust as needed */
    border-radius: 8px;
    box-sizing: border-box;
  }
  
  .doctor-details-box {
    text-align: left;
  }
  
  .close-info-box {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .doctor-info-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  .doctor-info-box h3 {
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #333;
  }
  
  .doctor-info-box p {
    margin: 5px 0;
    font-size: 0.95rem;
    color: #555;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 600px) {
    .doctor-info-box {
      width: 90%;
      left: 5% !important; /* Center the box on small screens */
      top: auto !important;
      right: auto !important;
      position: fixed; /* Fix the position for better responsiveness */
      bottom: 20px; /* Position near the bottom */
    }
  
    .doctor-info-image {
      width: 60px;
      height: 60px;
    }
  
    .doctor-info-box h3 {
      font-size: 1rem;
    }
  
    .doctor-info-box p {
      font-size: 0.85rem;
    }
  }
  