.faq-container {
  width: 83%;
  margin: 50px 0;
  background-color: #97a3c233;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
}

.faq-list {
  list-style: none;
  padding: 0;
  width: 70%;
}

.faq-item {
  margin: 25px 0;
  padding: 10px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
}

.faq-item.animate {
  opacity: 1;
  transform: translateY(0);
}

.faq-question {
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

.faq-arrow {
  margin-left: 10px;
}

.faq-answer {
  margin-top: 1rem;
  padding-left: 1rem;
  border-left: 2px solid #ccc;
  text-align: left;
}

.faq-divider {
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1.1rem 0 0;
}

@media (max-width: 768px) {
  .faq-container {
    width: 95%;
    padding: 50px 0;
  }
  .faq-list {
    width: 100%;
  }
}
