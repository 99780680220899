/* Language Selector Button */
.language-button {
    position: fixed;
    bottom: 15%; /* Position above the WhatsApp button */
    right: 3%;
    background-color: white;
    color: black;
    border-radius: 50px;
    padding: 10px 20px;
    z-index: 1001; /* Higher z-index to appear above WhatsApp button if needed */
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 1rem;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.3s;
    border: none;
    outline: none;
  }
  
  .language-button:hover {
    background-color: #A7185D;
    transform: scale(1.05);
    color: white;
  }
  
  .language-button img {
    margin-right: 10px;
    width: 2.2rem;
    height: 2.2rem;
  }
  
  /* Google Translate Element */
  #google_translate_element.visible {
    display: block;
    opacity: 0;
    transform: translateY(-20px);
    animation: fadeIn 0.3s forwards;
  }
  
  #google_translate_element {
    display: none;
    position: fixed;
    bottom: 22%; /* Adjust to appear just above the Language Selector button */
    right: 3%;
    background: #ffffff;
    border: 1px solid #ddd;
    padding: 15px;
    z-index: 1001;
    width: 250px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translateY(-20px);
  }
  
  #google_translate_element.show {
    opacity: 1;
    transform: translateY(0);
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Style the translate dropdown itself */
  .goog-te-combo {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    outline: none;
    background-color: #f9f9f9;
  }
  
  .goog-te-combo:hover {
    border-color: #128c7e;
    cursor: pointer;
  }
  
  /* Hide the Google branding */
  .goog-logo-link {
    display: none !important;
  }
  
  .goog-te-gadget img {
    display: none !important;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .language-button {
      bottom: 16%; /* Adjust for smaller screens if necessary */
    }
  
    #google_translate_element {
      width: 200px;
      padding: 10px;
      bottom: 24%; /* Adjust to maintain spacing */
    }
  }
  