.Loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin:30px 0;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color:  #840442;
    animation: spin 1s ease infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
