.patient-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 60px;
}

.appointments {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #4a4a4a;
  width: 26%;
  position: relative;
  background-color: #ffffff81;
  padding: 15px 20px;
  gap: 10px;


}

.dot {
  width: 5px;
  height: 5px;
  background-color: #4a4a4a;
  border-radius: 50%;
  display: inline-block;
}

.line {
  flex: 1;
  height: 1px;
  background-color: #4a4a4a;
  margin: 0 5px;
}

.appointments-icon {
  font-size: 1rem;
  color: #4a4a4a; /* Optional: Change color to make the icon stand out */
  display: flex;
  align-items: center;
  justify-content: center;
}

.appointments-text {
  font-size: 0.8rem;
}

.search-bar {
  width: 33%;
  margin: 0 20px;
  background-color: #ffffff81;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.search-bar i {
  padding: 10px;
  color: #4a4a4a;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 14px;
  color: #4a4a4a;
}

.current-date {
  font-size: 14px;
  color: #4a4a4a;
  font-weight: bold;
  width: 28%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.current-date i {
  cursor: pointer;
  color: #4a4a4a; /* Optional: Change color for better visibility */
}

/* Responsive Adjustments (Optional) */
@media (max-width: 768px) {
  .patient-header {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    margin-bottom: 10px;
  }
  .appointments,
  .current-date {
    display: none;

  }
  .appointments,
  .search-bar,
  .current-date {
    width: 90%;
    margin-bottom: 15px;
  }

  .appointments-text {
    margin-left: 10px;
  }
}
