@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

.dashboard {
  display: flex;

  background: linear-gradient(
    135deg,
    rgba(199, 214, 187, 1) 0%,
    rgba(237, 239, 240, 1) 22%,
    rgba(237, 238, 239, 1) 80%,
    rgba(249, 165, 168, 1) 100%
  );
  font-family: "Nunito", sans-serif !important;
  background-image: url(https://www.medbridgeaccess.com/static/banner/dash-back.png);
  background-size: cover;
  background-position: center;
  object-fit: cover;
  width: 100%;
  overflow-y: auto;
  overflow-y: auto;
}

.dashboard-overlay {
  width: 100%;

  height: 100%;
  overflow-y: auto;
}
.dashboard h1,
.dashboard h2,
.dashboard h3,
.dashboard h4 {
  font-weight: 600;
  font-family: "Nunito", sans-serif !important;
  color: #404659;
}
.dashboard h1 {
  font-weight: 400;
}

.dashboard-box {
  display: flex;
  width: 100%;
  height: 100%;
}
.dashboard-section {
  display: flex;
  flex-direction: column;
  width: 92%;
  padding: 20px 0;

}
.dashboard-section-box {
  display: flex;

  align-items: start;
  justify-content: space-around;
}
.dashboard-section-small-box {
  width: 60%;
}
.dashboard-left,
.dashboard-middle,
.dashboard-right {
  width: 29%;
  display: flex;
  flex-direction: column;
  height: 130vh;
}

.dashboard-middle {
  width: 31%;
}
.dashboard-left {
  gap: 20px;
  width: 30%;
  padding-left: 20px;
  box-sizing: border-box;
}

.dashboard-left-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dashboard-middle-slider {
  height: 30%;
}

.username {
  width: 100%;
  text-align: start;

  margin: 0;
}
.dashboard-right h2 {
  width: 80%;
  text-align: left;
  font-size: 20px;
}
.dashboard-left-box-header {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: self-start;

  width: 90%;
  margin-bottom: 10px;
  padding-left: 20px;
  box-sizing: border-box;
}

.dashboard-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard-section-header-right {
  width: 50%;
  display: flex;
  align-items: center;
}
.dashboard-left-box-header h4 {
  margin-bottom: 30px;
  font-size: 0.9rem;
  font-weight: 700;
}
.dashboard-left-box-header h1 {
  font-size: 2.2rem;
}
.dashboard-left-box-header p {
  margin: 10px 0;
  font-weight: 400;
  font-size: 1rem;
  color:#737ea0 ;

}
.actions {
  width: 45%;
  display: flex;

  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-left: 40px;
}

.actions button {
  margin: 0 10px;
  padding: 15px 15px;
  font-size: 1rem;
  cursor: pointer;
  width: 50%;
  border: none;
  outline: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.actions button i {
  font-size: 1.2rem;
}
.appointment-cards {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;

  /* Hide scrollbar by default */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}
.appointment-cards h2{
  width: 100%;
  text-align: left;
}

.appointment-cards .doctors-empty-state{
  background-color: white;
  width: 100%;
}
.appointment-card-header {
  text-align: left;
}
.appointment-card-header h3 {
  margin: 0;
  font-size: 1.3rem;
}

.appointment-card {
  background-color: rgba(255, 255, 255, 0.393);
  background-image: url(https://www.medbridgeaccess.com/static/banner/email-back.webp);
  background-size: cover;
  background-position: center;
  object-fit: cover;
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  cursor: pointer;
  color: black;
  padding: 20px 20px;
  width: 100%;
  margin: 0;
}

.appointment-card:hover {
  background-color: rgba(255, 255, 255);
}
.doctor-info {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.doctor-image {
  width: 100px;
  height: 110px;
  object-fit: cover;
}

.doctor-details {
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 90px;
}

.doctor-details h2 {
  font-size: 16px;
  margin: 0;
}

.doctor-details p {
  font-size: 0.8rem;
  margin: 2px 0;
  font-weight: 300;
}

.doctor-info-2 p {
  margin: 0;
}
.doctor-info-2 .branch {
  font-size: 18px;
  font-weight: 600;
}
.doctor-info-2 .specialization {
  font-size: 12px;
  color: rgba(43, 43, 43);
}
.datetime-info {
  display: flex;
  align-items: self-start;
  gap: 10px;
  color: #5c6580;
}

.datetime-pair {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 5px;
}

.datetime-key {
  color: rgba(43, 43, 43);
  margin-bottom: 2px;
  font-size: 15px;
}

.datetime-value {
  margin-top: 5px;
  font-weight: 300;
  font-size: 0.8rem;
}

.problem-description {
  text-align: start;
  margin-bottom: 15px;
}
.problem-description h4 {
  margin: 0;
  font-weight: 600;
}
.problem-description p {
  margin: 0;
}

.appointment-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.appointment-actions span {
  width: 40%;
  background-color: white;
  padding: 8px 0;
  border-radius: 10px;
  font-size: 14px;
}
.appointment-actions-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 33%;
}
.appointment-actions button {
  border: none;
  border-radius: 50%;
  padding: 10px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: white;
}

.appointment-actions button:hover {
  background-color: #d0d0d0;
}

.appointment-actions button img {
  width: 18px;
  height: 18px;
}

.book-appointment-button {
  background-color: black;
  color: white;
}
.free-quote-button {
  background-color: rgba(255, 255, 255, 0.503);
  color: black;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.user-links {
  width: 50%;
}

.link-button {
  text-decoration: none;
  width: 100%;
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 15px 15px;
  color: rgb(131, 130, 130);
  border-radius: 25px;
}

.link-button h2 {
  margin: 0;
  transition: background-color 0.3s ease;
  font-size: 18px;
}
.link-button i {
  font-size: 20px;
}

.link-button:hover {
  padding: 17px;
}

.your-doctors-section {
  margin: 20px 0;
  width: 100%;
}

.your-doctors-section h2 {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #333;
  text-align: left;
}

.doctors-row {
  display: flex;
  overflow-x: auto;
  padding-bottom: 10px;
  justify-content: start;
  align-items: center;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  gap: 20px;
  padding: 10px 0;
}

.doctors-row::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.doctor-wrapper {
  position: relative; /* To position the info box absolutely within */
  flex: 0 0 auto; /* Prevent shrinking and allow horizontal scroll */
  width: 31%; /* Adjust width as needed */
  height: 150px;
}

.doctor-thumbnail {
  width: 100%;
  height: 90%;
  object-fit: cover;
  border-radius: 0; /* Zero border radius as per requirement */
  cursor: pointer;
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.doctor-wrapper span p {
  margin: 0;

  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 5px;
}

.doctor-thumbnail.active {
  opacity: 1;
  transform: scale(1.05);
}

.doctor-thumbnail.inactive {
  opacity: 0.4;
}

.doctor-thumbnail:hover {
  opacity: 1;
}

/* Hide the default scrollbar and allow scrolling */
.doctors-row {
  -webkit-overflow-scrolling: touch;
}

/* Optional: Customize scrollbar appearance if needed */
/* Example for WebKit browsers */
.doctors-row::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  height: 0;
}

/* Overlay when Info Box is open */
.info-box-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999; /* Below the DoctorInfoBox which has 1000 */
}

.medical-history {
  background-color: white;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}
/* Hide the default scrollbar and allow scrolling */
.medical-history {
  -webkit-overflow-scrolling: touch;
}

/* Optional: Customize scrollbar appearance if needed */
/* Example for WebKit browsers */
.medical-history::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  height: 0;
}
.medical-history::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding: 15px 0;
}

.medical-history hr {
  border-bottom: 0px solid black;
  margin: 0;
  width: 100%;
}

.history-header h2 {
  font-size: 1.5rem;
  color: #333;
}

.history-actions .see-all {
  font-size: 0.9rem;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

.history-actions .see-all:hover {
  text-decoration: underline;
}

.history-content {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px 0;
  width: 90%;
}

.history-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  position: relative;

  z-index: 1;
}

.history-item:last-child {
  margin-bottom: 0;
}

.history-timeline {
  width: 30%; /* Adjusted width */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.history-date {
  font-size: 0.9rem;
  color: #666;
  margin-right: 10px;
  text-align: right;
  width: 100%;
}

.read-more-button {
  background: none;
  border: none;
  color: #2196f3;
  cursor: pointer;
  padding-left: 5px;
  font-size: 0.9em;
}

.read-more-button:hover {
  text-decoration: underline;
}

/* Remove any styles related to toggle buttons if present */

/* Optional: Add transitions for smooth expansion */
.history-description {
  transition: all 0.3s ease;
}
.history-toggle {
  cursor: pointer;
  background-color: #cbc6b0; /* Match parent background */
  border: 1px solid black;
  border-radius: 50%;
  padding: 5px;
  width: 24px;
  height: 24px;
  box-sizing: border-box; /* Include border in dimensions */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative;
  z-index: 2;
}

.history-toggle:hover {
  background-color: black;
  color: #fff;
}

.history-details {
  flex-grow: 1;
  border-radius: 5px;
  padding: 0px 15px 10px;
  text-align: left;
  border-left: 4px solid #000; /* This will be overridden by inline styles */
  width: 70%;
}

.history-title {
  font-size: 1.2rem;
  margin-bottom: 5px;
  margin-top: 0;
  color: #333;
}

.history-description {
  font-size: 0.95rem;
  color: #555;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.history-item.expanded .history-description {
  max-height: 500px;
  opacity: 1;
}

.history-item.collapsed .history-description {
  max-height: 60px;
  opacity: 1;
}

/* Not Found Message */
.not-found-message {
  font-size: 1rem;
  color: #888;
  text-align: center;
  margin-top: 20px;
}

/* Carousel Slide Styles */
.carousel-slide {
  position: relative;
  text-align: center;
  color: #fff;
}

.carousel-image {
  width: 100%;
  height: 350px; /* Adjust height as needed */
  object-fit: cover;
}

.carousel-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.carousel-content h2 {
  margin-bottom: 10px;
  font-size: 24px;
}

.carousel-content p {
  font-size: 16px;
}

/* Appointment Card Slider Buttons */
.appointment-card-slider {
  display: flex;
  align-items: center;
  width: 100%;
}

.arrow-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #007bff;
  transition: color 0.3s ease;
}

.arrow-button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.arrow-button:hover:not(:disabled) {
  color: #0056b3;
}
/* src/CSS/UserDashboard.css */

.doctors-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  text-align: center;
  margin: 20px 0;
}

.empty-state-image {
  width: 200px;
  height: 200px;
}

.doctors-empty-state h3 {
  font-size: 1.2rem;
  margin: 0px;
  color: #333;
}

.doctors-empty-state p {
  font-size: 0.7rem;
  color: #666;
  margin: 0;
  margin-bottom: 20px;
}

.book-now-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #2196F3;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.book-now-button:hover {
  background-color: #1976D2;
}


/* Responsive Design */
@media (max-width: 768px) {
  .dashboard-section-box {
    flex-direction: column;
  
    align-items: center;
    justify-content: space-around;
    gap: 30px;
    padding-bottom: 30px;
  }
  .dashboard-left-box-header {
    width: 95%;
  }
  .dashboard-left-box-header p {
 width: 70%;
}
  .dashboard-middle {
    width: 100%;
}
  .history-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .history-timeline {
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
    width: auto;
  }

  .history-date {
    margin-bottom: 0;
    margin-right: 10px;
  }

  .history-line {
    display: none;
  }

  .history-toggle {
    margin-left: auto;
    margin-right: 0;
    top: 0;
  }
}

.medical-reports {
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding: 10px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 30%;
}

.reports-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0px;
}

.reports-header h3 {
  font-size: 18px;
  color: #333;
  margin: 0;
}

.menu-icon {
  font-size: 20px;
  color: #6c757d;
}

.reports-content {
  display: flex;
  gap: 20px; /* Adjust the spacing between report items as needed */
  width: 100%;
  overflow-x: auto;
  padding: 27px 0;
}

.reports-content::-webkit-scrollbar {
  height: 6px;
}

.reports-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

.report-item {
  background-color: transparent;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 15px;
  flex: 0 0 170px; /* Set a fixed width for each item */
  box-sizing: border-box;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
}

.report-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding-top: 10px;
}
.report-details .report-icon {
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -75%);
  background-color: white;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.report-details .report-icon i {
  font-size: 30px;
}

.report-name {
  font-size: 1rem;
  color: #000;
  margin: 0;
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.report-details .report-date {
  font-size: 0.7rem;
  color: #6c757d;
  margin: 0;
  margin-top: 4px;
}

.not-found-message {
  font-size: 16px;
  color: #6c757d;
  margin: 20px 0;
}

.close-sidebar {
  display: none;
}

.not-found-message {
  width: 100%;
  color: #929292 !important;
}

@media (max-width: 768px) {
  .dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .dashboard-section {
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .dashboard-left {
    width: 95%;
    padding-left: 0;
  }
  .dashboard-right {
    width: 95%;
  }
  .dashboard-left,.dashboard-middle, .dashboard-right{
    height: auto;
    max-height: 130vh;
  }
  .actions {
    flex-direction: column;
  }
  .actions button {
    margin-bottom: 10px;
    width: 90%;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    height: 100vh;
    width: 250px;
    transition: left 0.3s ease-in-out;
    background: linear-gradient(
      to bottom right,
      #ded1fd 0%,
      #f1f1f2 30%,
      #e3e3e4 75%,
      #faa293 100%
    );
    z-index: 1000;
  }
  .nav-items {
    display: flex;
    flex-direction: column;
    height: 80%;
    justify-content: center;
    align-items: center;
  }
  .sidebar .logo-box {
    display: none;
  }
  .sidebar.open {
    transform: translateX(0);
  }

  .close-sidebar {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 40px;
    cursor: pointer;
  }
  .sidebar-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.752);
    z-index: 999;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .sidebar-overlay.active {
    display: block;
    opacity: 1;
  }
}
.health-metrics-container {
  background-color: #ffffffa0;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding: 10px 20px 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.health-metrics-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 95%;
}

.health-metrics-header h2 {
}

.health-metrics-see-all {
  color: #4a90e2;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
}
.health-metric {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
}
.health-metric h4 {
  margin: 0;
  font-size: 1rem;
}
.health-metric p {
  font-size: 0.7rem;
  margin-top: 3px;
  color: #828282;
}
.health-metrics-grid {
  display: flex;
  gap: 50px;
  flex-wrap: nowrap;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  flex-direction: column;
  width: 95%;
}

.health-metrics-grid::-webkit-scrollbar {
  display: none; /* WebKit */
}

.health-metrics-card {
  background-color: rgba(255, 255, 255, 0.068);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  height: 160px;
  width: 100%;
  margin-top: 20px;
}

.health-metrics-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 20%;
  background-color: rgb(234, 233, 233);
  height: 100%;
  justify-content: center;
  text-align: center;
}

.health-metrics-title {
  color: #666;
  font-size: 0.7rem;
}

.health-metrics-value {
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
}

.health-metrics-status {
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 12px;
  width: fit-content;
  text-transform: capitalize;
}

.health-metrics-icon {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.health-metrics-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  color: #666;
}

/* Optional: Adjust the media query to handle responsiveness */
@media (max-width: 768px) {
  .health-metrics-grid {
    /* Keep flex-wrap: nowrap to maintain horizontal scrolling */
  }

  .health-metrics-card {
    /* Adjust min-width if needed */
    min-width: 130px;
  }
}

/* Container for the entire section */
.actions-dwhs {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;

  width: 90%;
  padding: 20px 20px;
}
.dwhs {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  padding-top: 0;
}
.dwhs-header {
  width: 100%;
  text-align: right;
}
.dwhs-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  gap: 5px;
}

/* Date Card Styles */
.dwhs-dateCard {
  background-color: #f99e99; /* Light pink background */
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 13vh;
}

.dwhs-day {
  font-size: 2.2em;
  font-weight: 600;
  color: #fff;
}

.dwhs-month {
  font-size: 0.8em;
  color: #fff;
}

/* Info Cards Container */
.dwhs-infoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 55%;
  gap: 10px;
}

.dwhs-infoContainer-box {
  width: 35%;
  padding: 15px 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: relative;
  padding-top: 35px;
}
.dwhs-container img {
  width: 30px;
}
.dwhs-infoCard {
  text-align: center;
}

.dwhs-label {
  font-size: 0.9em;
  font-weight: 700;

  margin-bottom: 5px;
}

.dwhs-value {
  font-size: 0.65em;
  color: rgb(243, 206, 138);
}

.dwhs-infoCard-img {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dwhs-input {
  width: 60px;
  padding: 5px;
  font-size: 1.2em;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Update Button */
.dwhs-updateButton {
  color: #007bff; /* Blue button */
  border: none;
  cursor: pointer;
  font-size: 0.9em;
  background-color: transparent;
  padding: 10px 0 0;
}

.dwhs-updateButton:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .dwhs-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .dwhs-infoContainer {
    margin-left: 0;
  }
}
