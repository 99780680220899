.medical-process {
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.medical-steps {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 20px;
  width: 80%;
}

.medical-step {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  width: 150px;
  margin: 10px;
}

.medical-step.animate {
  opacity: 1;
  transform: translateY(0);
}

.medical-icon {
  font-size: 40px;
  margin-bottom: 10px;
  background-color: #e0b3bf;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.medical-icon img {
  width: 70%;
}

.medical-title {
  font-size: 18px;
  color: #4a4a4a;
}

.medical-description {
  font-size: 14px;
  color: #7a7a7a;
}

.medical-quote-button {
  background-color: #a7185d;
  color: white;
  border: none;
  padding: 13px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
}

.medical-quote-button:hover {
  background-color: #86144b;
}

@media (max-width: 768px) {
  .medical-process {
    padding: 20px 0;
  }
  .medical-steps {
    flex-direction: column;
  }
  .medical-step {
    width: 100%;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .medical-title {
    margin: 0;
  }
  .medical-description {
    margin-top: 5px;
  }
  .process-heading {
    margin-bottom: 0;
  }
}
