.compassionate-care {
    font-family: Arial, sans-serif;
    height: 800px;
    position: relative;
  }
  
  .banner {
    background-image: url('https://www.medbridgeaccess.com/static/banner/exercise.webp');
    background-size: cover;
    background-position: center;
    object-fit: cover;
    position: relative;
    color: white;
    text-align: center;
    height: 500px;
    
  }
  
  .overlay {
    background-color: rgba(0, 0, 0, 0.229);
    background-size: cover;
    background-position: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
 /* Container for the play button and ripple */
.play-button-container {
  position: relative;
  width: 60px;
  height: 60px;
  /* Optional: To center the container if needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Play Button Styling */
.overlay .play-button-1 {
  position: relative; /* Ensure it stays above the ripple */
  font-size: 1.2rem;
  background-color: #9a054d;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2; /* Higher z-index to stay above ripple */
  cursor: pointer; /* Optional: Change cursor on hover */
  transition: background-color 0.3s ease; /* Optional: Hover effect */
}

.overlay .play-button-1:hover {
  background-color: #b0056f; /* Optional: Darken on hover */
}

/* Ripple Effect Styling */
.ripple {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px; /* Initial size */
  height: 40px; /* Initial size */
  background: white; /* Semi-transparent to match button color */
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: ripple-animation 2s infinite;
  z-index: 1; /* Below the play button */
}

/* Keyframes for the ripple animation */
@keyframes ripple-animation {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0.6;
  }
  20% {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0.5;
  }
  40% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0.4;
  }
  60% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0.3;
  }
  80% {
    transform: translate(-50%, -50%) scale(2.5);
    opacity: 0.15;
  }
  100% {
    transform: translate(-50%, -50%) scale(3);
    opacity: 0;
  }
}

.ripple:nth-child(2) {
  animation-delay: 0s;
}

.ripple:nth-child(3) {
  animation-delay: 0.5s;
}

.ripple:nth-child(4) {
  animation-delay: 1s;
}

.ripple:nth-child(5) {
  animation-delay: 1.5s;
}

.ripple:nth-child(6) {
  animation-delay: 2s;
}






  .overlay p{
    width: 70%;
  }
  .testimonial {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 12%;
    left: 50%;
    width: 75%;
    transform: translateX(-50%);
    height: 230px;
  }
  
  .testimonial-content {
    flex: 1;
    background-color: #ac054e;
    color: white;
    padding: 40px;
   text-align: left;
   height: 100%;
  }
  
  .testimonial-content h2,
  .testimonial-content h3 {
    margin: 0 0 20px 0;
  }
  
  .testimonial-content p {
    margin-bottom: 20px;
  }
  
  .contact-button {
   color: white;
   background-color: #740133;
    border: none;
    padding: 15px 25px;
    border-radius: 5px;
    cursor: pointer;

  }
  
  .testimonial-review {
    flex: 1;
    background-color: rgb(250, 248, 248);
    padding: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-around;
  height: 100%;

  }
  
  .review {
    position: relative;
    text-align: left;

  }
  .overlay-playbutton{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .review p {
    margin: 0 0 20px 0;
  }
  .reviewer-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .reviewer {
    display: flex;
    align-items: center;
  }
  
  .reviewer img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .reviewer-info{
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .double-quote-icon{
    width: 20%;
  }
  .double-quote-icon img{
 width: 100%;
  }
  .rating {
  
   font-size: 22px;
    color: gold;
  }
  .reviewer-initial {
    width: 50px;
    height: 50px;
    border-radius: 50%;
   /* You can change the color */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 400;
    margin-right: 15px;
}

@media (max-width: 768px) {
.compassionate-care{
      height: 122vh;
    }
    .banner{
      height: 65vh;
    }
.overlay{
  font-size: 11px;
  
}
.overlay p {
  width: 80%;
}

.testimonial{
  display: flex;
  flex-direction: column;
  font-size: 12px;
  bottom: 20%;
  width: 87%;
 
}
.testimonial-content{
  border-radius: 20px 20px 0 0;
}
.testimonial-review{
  border-radius: 0 0 20px 20px;
}



}
@media (max-width:378px) {
  .compassionate-care {
    height: 160vh;
}
.testimonial {
 
  bottom: 23%;
  
}

}
  

