.services-container {
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
    padding: 60px 0px;
    
    background-color: rgb(248, 246, 246);
}

.services-header {
    margin-bottom: 20px;
    width: 85%;
}

.services-header h3 {
    color: #840442;
}

.services-header h2 {
    margin: 10px 0;
}

.services-cards {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px; 

}

.service-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    width: 28%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    background-color: white;
    height: 440px;
    margin: 15px 0;
    border-bottom: 2px solid #840442 ;
}

.service-image {
    width: 100%;
    height: 63%;
    border-radius: 7px;
    object-fit: cover;
}

.service-card h3 {
    margin: 10px 0;
}

.service-card p {
    padding: 0 10px;
    font-size: 14px;
}

.read-more {
    display: inline-block;
    margin: 10px 0;
    color:   #a30552;
    text-decoration: none;
}
.read-more i{
    color: white;
    background-color:  #a30552 ;
    border-radius: 50%;
    padding: 8px 9px;
    margin-left: 3px;
}

.all-services-button {
    background-color: #a30552;
    color: white;
    border: none;
    padding: 13px 25px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 17px;
    margin: 40px 0 30px;
}

.all-services-button:hover {
    background-color: #d81b60;
}
@media (max-width: 768px) {
    .services-container {
        font-size: 12px;
        padding: 40px 10px;
        }

        .service-card {
           
            width: 100%;
            height: 370px;
          
        }




}