/* Base modal styles */
.diagnosis-add-diagnosis-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 8px;
  padding: 2rem;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  outline: none;
}

.diagnosis-add-diagnosis-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal header styles */
.diagnosis-add-diagnosis-modal h2 {
  color: #2c3e50;
  margin: 0 0 1.5rem 0;
  font-size: 1.5rem;
  font-weight: 600;
  border-bottom: 2px solid #e9ecef;
  padding-bottom: 1rem;
}

/* Form group styles */
.diagnosis-form-group {
  margin-bottom: 1.5rem;
}

.diagnosis-form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #495057;
  font-weight: 500;
}

.diagnosis-required {
  color: #dc3545;
  margin-left: 4px;
}

/* Textarea styles */
.diagnosis-form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1.5;
  transition: border-color 0.2s ease-in-out;
  resize: vertical;
}

.diagnosis-form-group textarea:focus {
  border-color: #4a90e2;
  outline: none;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

/* Dropzone styles */
.diagnosis-dropzone {
  border: 2px dashed #ced4da;
  border-radius: 4px;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.diagnosis-dropzone:hover {
  border-color: #4a90e2;
  background-color: rgba(74, 144, 226, 0.05);
}

.diagnosis-drag-over {
  border-color: #4a90e2;
  background-color: rgba(74, 144, 226, 0.1);
}

.diagnosis-dropzone-area {
  margin-top: 1rem;
  color: #6c757d;
}

.diagnosis-dropzone-area span {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Error message styles */
.diagnosis-error-message {
  background-color: #fff3f3;
  border: 1px solid #dc3545;
  border-radius: 4px;
  color: #dc3545;
  padding: 0.75rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

/* Button styles */
.diagnosis-form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.diagnosis-cancel-button,
.diagnosis-submit-button {
  padding: 0.5rem 1.25rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.diagnosis-cancel-button {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  color: #495057;
}

.diagnosis-cancel-button:hover {
  background-color: #e9ecef;
}

.diagnosis-submit-button {
  background-color: #4a90e2;
  border: 1px solid #4a90e2;
  color: #ffffff;
}

.diagnosis-submit-button:hover {
  background-color: #357abd;
  border-color: #357abd;
}

.diagnosis-submit-button:disabled {
  background-color: #a8c7eb;
  border-color: #a8c7eb;
  cursor: not-allowed;
}

/* Responsive adjustments */
@media (max-width: 576px) {
  .diagnosis-add-diagnosis-modal {
      width: 95%;
      padding: 1.5rem;
  }

  .diagnosis-form-actions {
      flex-direction: column;
      gap: 0rem;
  }

  .diagnosis-cancel-button,
  .diagnosis-submit-button {
      width: 100%;
  }
}