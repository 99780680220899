.mobile-header {
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-sizing: border-box;
  
  }
  
  .mobile-logo {
    height: 40px;
  }
  
  .menu-toggle {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .mobile-header {
      display: flex;
      width: 93%;
    }
  }