/* AboutUs.css */

/* Container Styles */
.senior-care-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 95%;
  margin: 30px 0;
  overflow-x: hidden;
}

/* Image Container */
.senior-care-image {
  position: relative;
  width: 40%;
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.senior-care-image.visible {
  opacity: 1;
  transform: translateX(0);
}

/* Image Styles */
.senior-care-image img {
  position: relative;
  height: 450px;
  border-radius: 8px;
  object-fit: cover;
  z-index: 2;
  width: 85%;
}

/* New Border Box Styles */
.border-box {
  position: absolute;
  top: 10%;
  right: 0%;
  width: 70%;
  height: 80%;
  border: 3px solid #d12d6a;
  box-sizing: border-box;
  z-index: 1;
  border-radius: 15px;
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.border-box.visible {
  opacity: 1;
  transform: scale(1);
}

/* Play Button Container with Ripple Effect */
.senior-care-image .play-button-container {
  position: absolute;
  bottom: 5%;
  left: 10%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 1s ease-out, transform 1s ease-out;
  z-index: 3;
}

.play-button-container.visible {
  opacity: 1;
  transform: scale(1);
}

/* Play Button Styling */
.play-button-1 {
  position: relative; /* Ensure it stays above the ripple */
  font-size: 1.2rem;
  background-color: #d12d6a; /* Updated color to match existing styles */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2; /* Higher z-index to stay above ripple */
  cursor: pointer; /* Change cursor on hover */
  transition: background-color 0.3s ease; /* Hover effect */
}
.play-button-1 i{
  color: white;
}

.play-button-1:hover {
  background-color: #b0056f; /* Darken on hover */
}

/* Ripple Effect Styling */
.ripple {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px; /* Initial size */
  height: 40px; /* Initial size */
  background: rgba(255, 255, 255, 0.6); /* Semi-transparent */
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: ripple-animation 2s infinite;
  z-index: 1; /* Below the play button */
}

@keyframes ripple-animation {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0.6;
  }
  20% {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0.5;
  }
  40% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0.4;
  }
  60% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0.3;
  }
  80% {
    transform: translate(-50%, -50%) scale(2.5);
    opacity: 0.15;
  }
  100% {
    transform: translate(-50%, -50%) scale(3);
    opacity: 0;
  }
}

/* Staggered Ripple Animations */
.play-button-container .ripple:nth-child(2) {
  animation-delay: 0s;
}

.play-button-container .ripple:nth-child(3) {
  animation-delay: 0.5s;
}

.play-button-container .ripple:nth-child(4) {
  animation-delay: 1s;
}

.play-button-container .ripple:nth-child(5) {
  animation-delay: 1.5s;
}

.play-button-container .ripple:nth-child(6) {
  animation-delay: 2s;
}

/* Senior Care Content */
.senior-care-content {
  width: 50%;
  text-align: left;
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.senior-care-content.visible {
  opacity: 1;
  transform: translateX(0);
}

.senior-care-content h2 {
  color: #d12d6a;
}

.senior-care-content h1 {
  margin-top: 0;
}

.senior-care-content p {
  color: #666;
}

/* StyledList.css */
.container {
  display: flex;
  flex-wrap: wrap;
}

.list-column {
  flex: 1;
  min-width: 200px;
}

.senior-care-content ul {
  list-style: none;
  padding-left: 0;
}

.senior-care-content li {
  position: relative;
  margin-bottom: 10px;
  padding-left: 25px;
}

.senior-care-content ul li:before {
  content: "\21D2"; /* Unicode character for arrow */
  position: absolute;
  left: 0;
  color: #d63384;
}

.senior-care-features {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.senior-care-features.visible {
  opacity: 1;
  transform: scale(1);
}

.feature {
  display: flex;
  align-items: center;
  opacity: 0;
  transform: rotateY(-90deg);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.feature.visible {
  opacity: 1;
  transform: rotateY(0);
}

.feature .icon {
  font-size: 24px;
  background-color: #d12d6a;
  margin-right: 10px;
  color: white;
  padding: 8px 15px;
  border-radius: 4px;
}

.feature span {
  color: #666;
  font-size: 15px;
}

.feature span h4 {
  margin: 0;
  font-size: 20px;
  color: black;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .senior-care-image img {
    width: 88%;
  }

  .play-button-container {
    bottom: 0;
    left: 3%;
  }

  .senior-care-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    width: 100%;
    margin: 30px 0;
  }

  .senior-care-content h2 {
    width: 100%;
  }

  .senior-care-image {
    width: 90%;
  }

  .senior-care-content {
    width: 90%;
    text-align: left;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .feature .icon {
    font-size: 16px;
    margin-right: 6px;
  }

  .feature {
    text-align: center;
  }

  .feature span {
    color: #666;
    font-size: 8px;
    text-align: left;
  }

  .feature span h4 {
    margin: 0;
    font-size: 9px;
    color: black;
  }

  .senior-care-features {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 375px) {
  .senior-care-content {
    font-size: 12px;
  }
}
